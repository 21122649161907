import Api from 'src/infrastructure/services/Api';
import { convertObjectKeysToCamelCase } from 'src/utils/parse';
import { MediaProcessStatus } from 'src/domain/Media/modules/MediaUpload/interfaces/MediaProcessProps';
const MAX_FILES_TO_RETURN_FROM_API_MEDIA_PROCESSING = 50;
const download = async ({ code }) => {
    const response = await Api.call({
        method: 'GET',
        url: `${process.env.API_GATEWAY}/media/${code}/url`,
    });
    return response.data;
};
const update = async ({ code, name, language, isPublic }) => {
    const response = await Api.call({
        method: 'PUT',
        url: `${process.env.API_GATEWAY}/media/${code}`,
        data: {
            name,
            language,
            isPublic,
        },
    });
    return response.data;
};
const getMediasStatusByDateInterval = async ({ startDate, endDate, }) => {
    const { data } = await Api.call({
        method: 'GET',
        url: `${process.env.API_GATEWAY}/media?status=${Object.values(MediaProcessStatus)
            .map((mediaStatus) => mediaStatus)
            .join(',')}&startDate=${startDate}&endDate=${endDate}&direction=desc
        &key=createDate&page=0&elementPerPage=${MAX_FILES_TO_RETURN_FROM_API_MEDIA_PROCESSING}`,
    });
    return data.content;
};
const getSignature = async ({ code }) => {
    const { data } = await Api.call({
        method: 'GET',
        url: `${process.env.API_GATEWAY}/authorization/media/${code}/signature`,
    });
    return data;
};
const getMediaDataByMediaCode = async (code) => {
    const { data } = await Api.call({
        method: 'GET',
        url: `${process.env.API_GATEWAY}/v2/media/${code}/detail`,
    });
    return convertObjectKeysToCamelCase(data);
};
const getMediaCount = async () => {
    const { data } = await Api.call({
        method: 'GET',
        url: `${process.env.API_GATEWAY}/v2/folder/media/count`,
    });
    return data;
};
const getMediaDurationCount = async () => {
    const { data } = await Api.call({
        method: 'GET',
        url: `${process.env.API_GATEWAY}/v2/folder/media/duration/count`,
    });
    return data;
};
const getLatestMedias = async (amount) => {
    const { data } = await Api.call({
        method: 'GET',
        url: `${process.env.API_GATEWAY}/v2/folder/media/recents`,
        params: {
            elementPerPage: amount,
        },
    });
    return data;
};
export { download, update, getMediasStatusByDateInterval, getSignature, getMediaDataByMediaCode, getMediaCount, getMediaDurationCount, getLatestMedias, };
