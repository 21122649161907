import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { faAnalytics, faMoneyBillsSimple, faInfoCircle, faCloudArrowDown, faMessages, faFileVideo, } from 'src/application/icons/fontAwesome/light';
import { AdvantageInfo } from '../AdvantagesInfo';
import styles from './styles.module.scss';
export const AdvantagesForYou = () => {
    const { t } = useTranslation();
    const translatePrefix = 'home.welcome_home.advantages_card.advantages_modal.advantages_for_you';
    return (_jsxs("div", { className: styles.advantagesGroup, children: [_jsxs("div", { className: styles.row, children: [_jsx(AdvantageInfo, { icon: faMoneyBillsSimple, title: t(`${translatePrefix}.advantage1.title`), description: t(`${translatePrefix}.advantage1.description`) }), _jsx(AdvantageInfo, { icon: faCloudArrowDown, title: t('home.welcome_home.advantages_card.advantages_for_you.advantage2.title'), description: `${t('home.welcome_home.advantages_card.advantages_for_you.advantage2.description')}.` })] }), _jsxs("div", { className: styles.row, children: [_jsx(AdvantageInfo, { icon: faInfoCircle, title: t(`${translatePrefix}.advantage2.title`), description: t(`${translatePrefix}.advantage2.description`) }), _jsx(AdvantageInfo, { icon: faMessages, title: t(`${translatePrefix}.advantage4.title`), description: t(`${translatePrefix}.advantage4.description`) })] }), _jsxs("div", { className: styles.row, children: [_jsx(AdvantageInfo, { icon: faAnalytics, title: t('home.welcome_home.advantages_card.advantages_for_you.advantage1.title'), description: t(`${t('home.welcome_home.advantages_card.advantages_for_you.advantage1.description')}.`) }), _jsx(AdvantageInfo, { icon: faFileVideo, title: t(`${translatePrefix}.advantage3.title`), description: t(`${translatePrefix}.advantage3.description`) })] })] }));
};
