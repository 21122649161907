import { getLatestImport, retryFailedImport, startImport, validateImportKey, } from 'src/infrastructure/endpoints/importMediaProvider';
class ImportMediaService {
    async validateImportKey({ provider, token }) {
        return validateImportKey({ provider, token });
    }
    async getLatestImport() {
        return getLatestImport();
    }
    async startImport({ provider, token }) {
        return startImport({
            provider,
            token,
        });
    }
    async retryFailedImport({ item }) {
        return retryFailedImport({ item });
    }
}
export default new ImportMediaService();
