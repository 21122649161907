import { getMenuToolsItems } from 'src/infrastructure/endpoints/menuTools';
import { menuItems } from './constants';
import { MenuGroupColors } from './interfaces';
class MenuToolsService {
    async getMenuToolsItems() {
        const groupMenu = await getMenuToolsItems();
        const menuGroups = groupMenu.groups.map((groupMenuItem) => {
            const items = groupMenuItem.items.reduce((acc, item) => {
                if (menuItems[item]) {
                    acc.push({
                        ...menuItems[item],
                        bgColor: MenuGroupColors[groupMenuItem.title],
                    });
                }
                return acc;
            }, []);
            return {
                title: groupMenuItem.title,
                items,
            };
        });
        return {
            groups: menuGroups,
            showAllToolsLink: groupMenu.showAllToolsLink,
        };
    }
}
export default new MenuToolsService();
