// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TjH5jy1OegvlZtURebSU{padding:1.5rem;gap:1rem}.TjH5jy1OegvlZtURebSU .CXFEnxFyZuDeUZGuPRli{display:flex;justify-content:space-between;align-items:center;gap:1rem;--hc-h-margin: 0}.TjH5jy1OegvlZtURebSU .CXFEnxFyZuDeUZGuPRli .cDyhbarhMXwsiGAYJ2to{display:flex;gap:1rem}.YdxPQ8NkcVcFEXh_o3RA{width:100%;height:100%;min-height:30rem}`, "",{"version":3,"sources":["webpack://./src/application/pages/Home/containers/LastUploads/styles.module.scss","webpack://./src/application/pages/Home/styles/home-card.scss"],"names":[],"mappings":"AAEA,sBCDE,cAAA,CACA,QAAA,CDGA,4CCCA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,QAAA,CACA,gBAAA,CDFE,kECMF,YAAA,CACA,QAAA,CDDF,sBACE,UAAA,CACA,WAAA,CACA,gBAAA","sourcesContent":["@import '../../styles/home-card.scss';\n\n.container {\n  @include home-card;\n\n  .header {\n    @include home-card-header;\n\n    .headerActions {\n      @include home-card-header-actions;\n    }\n  }\n}\n\n.contentLoader {\n  width: 100%;\n  height: 100%;\n  min-height: 30rem;\n}\n","@mixin home-card {\n  padding: 1.5rem;\n  gap: 1rem;\n}\n\n@mixin home-card-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 1rem;\n  --hc-h-margin: 0;\n}\n\n@mixin home-card-header-actions {\n  display: flex;\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TjH5jy1OegvlZtURebSU`,
	"header": `CXFEnxFyZuDeUZGuPRli`,
	"headerActions": `cDyhbarhMXwsiGAYJ2to`,
	"contentLoader": `YdxPQ8NkcVcFEXh_o3RA`
};
export default ___CSS_LOADER_EXPORT___;
