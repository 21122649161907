// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XSI_6tHMydvNS_7pShky{border:.0625rem solid var(--hc-color-neutral-200);border-radius:.5rem;height:4.375rem;padding:.25rem}.XSI_6tHMydvNS_7pShky .GDQtZhfYCoQBRbNmfF1e{align-items:center;display:table-cell;padding:.5rem .75rem .75rem;margin-bottom:.375rem}.XSI_6tHMydvNS_7pShky .GDQtZhfYCoQBRbNmfF1e .FUX6eoWZVBd6HLpejSNA{font-weight:var(--hc-font-weight-regular);font-size:var(--hc-size-text-small);line-height:var(--hc-line-height-large);padding-left:.5rem;width:18.625rem}.XSI_6tHMydvNS_7pShky .q5IpSuByUx5XWGqC_rgy{padding:0 .875rem}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/MediaUpload/components/ImportMediaProvider/progress/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iDAAA,CACA,mBAAA,CACA,eAAA,CACA,cAAA,CAEA,4CACE,kBAAA,CACA,kBAAA,CACA,2BAAA,CACA,qBAAA,CAEA,kEACE,yCAAA,CACA,mCAAA,CACA,uCAAA,CACA,kBAAA,CACA,eAAA,CAIJ,4CACE,iBAAA","sourcesContent":[".progress {\n  border: 0.0625rem solid var(--hc-color-neutral-200);\n  border-radius: 0.5rem;\n  height: 4.375rem;\n  padding: 0.25rem;\n\n  .name {\n    align-items: center;\n    display: table-cell;\n    padding: 0.5rem 0.75rem 0.75rem;\n    margin-bottom: 0.375rem;\n\n    .title {\n      font-weight: var(--hc-font-weight-regular);\n      font-size: var(--hc-size-text-small);\n      line-height: var(--hc-line-height-large);\n      padding-left: 0.5rem;\n      width: 18.625rem;\n    }\n  }\n\n  .progressBar {\n    padding: 0 0.875rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progress": `XSI_6tHMydvNS_7pShky`,
	"name": `GDQtZhfYCoQBRbNmfF1e`,
	"title": `FUX6eoWZVBd6HLpejSNA`,
	"progressBar": `q5IpSuByUx5XWGqC_rgy`
};
export default ___CSS_LOADER_EXPORT___;
