import { useQuery } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import { useMemo } from 'react';
import MediaShareService from '../../services/MediaShareService';
const MAX_SHARE_AMOUNT = 3;
const useMediasShare = () => {
    const { data, ...rest } = useQuery({
        queryKey: [QueryKeys.MEDIA_SHARE],
        queryFn: () => MediaShareService.getMediasShare(),
    });
    const isMediaShared = (mediaCode) => data?.some((media) => media.mediaCode === mediaCode);
    const isMaxAmountShared = useMemo(() => {
        return data?.length >= MAX_SHARE_AMOUNT;
    }, [data?.length]);
    return { data, ...rest, isMediaShared, isMaxAmountShared };
};
export default useMediasShare;
