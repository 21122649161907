import { jsx as _jsx } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import { MediaPreviewModal } from 'src/domain/Media/components/Modal/MediaPreview';
import { NiceModal } from 'src/infrastructure/libs/NiceModal';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { VIDEO_IMPORT_SLIDE, VIDEO_TUTOR_ADD } from '../../contants';
const useSuggestions = () => {
    const { t } = useTranslation();
    function openMediaPreview({ slideID, media }) {
        NiceModal.show(MediaPreviewModal, {
            media,
            withDownloadButton: false,
        });
        EventPrepAndTrackService.sendDebouncedInteractClick(`${IntentEvent.HOME_SLIDE_ITEM_PREFIX}.${slideID}`);
    }
    const suggestions = [
        {
            id: 1,
            text: t('home.default_home.suggestions_card.slides.suggestion1'),
            smallText: t('home.default_home.suggestions_card.slides.know_more'),
            action: () => openMediaPreview({ media: VIDEO_TUTOR_ADD, slideID: 1 }),
        },
        {
            id: 2,
            text: t('home.default_home.suggestions_card.slides.suggestion2'),
            smallText: t('home.default_home.suggestions_card.slides.know_more'),
            action: () => openMediaPreview({ media: VIDEO_TUTOR_ADD, slideID: 2 }),
        },
        {
            id: 3,
            text: (_jsx(Trans, { i18nKey: 'home.default_home.suggestions_card.slides.suggestion3', components: { br: _jsx("br", {}), span: _jsx("span", {}) } })),
            smallText: t('home.default_home.suggestions_card.slides.know_more'),
            action: () => openMediaPreview({ media: VIDEO_TUTOR_ADD, slideID: 3 }),
        },
        {
            id: 4,
            text: t('home.default_home.suggestions_card.slides.suggestion4'),
            smallText: t('home.default_home.suggestions_card.slides.see_how'),
            action: () => openMediaPreview({ media: VIDEO_IMPORT_SLIDE, slideID: 4 }),
        },
        {
            id: 5,
            text: t('home.default_home.suggestions_card.slides.suggestion7'),
            smallText: t('home.default_home.suggestions_card.slides.i_want_test'),
            action: () => {
                window.open(process.env.SURVICATE_CHAPTER_FORM_URL, '_blank');
            },
        },
        {
            id: 6,
            text: t('home.default_home.suggestions_card.slides.suggestion5'),
        },
        {
            id: 7,
            text: (_jsx(Trans, { i18nKey: 'home.default_home.suggestions_card.slides.suggestion6', components: { br: _jsx("br", {}), span: _jsx("span", {}) } })),
        },
    ];
    return suggestions;
};
export default useSuggestions;
