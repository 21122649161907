import { jsx as _jsx } from "react/jsx-runtime";
import { useMediaImports } from 'src/domain/Media/hooks';
import { ImportStatus } from 'src/domain/Media/components/UploadAndImport/ImportMediaProvider/constants';
import Discovered from './discovered';
import Failed from './failed';
import Progress from './progress';
import Success from './success';
import styles from './styles.module.scss';
const ImportItemStates = {
    [ImportStatus.CREATED]: Discovered,
    [ImportStatus.COMPLETED]: Success,
    [ImportStatus.DISCOVERED]: Discovered,
    [ImportStatus.FAILED]: Failed,
    [ImportStatus.PROCESSING]: Progress,
};
const ImportMediaProviderList = () => {
    const { getAllImports } = useMediaImports();
    return (_jsx("div", { className: styles.container, children: getAllImports.data &&
            Object.values(getAllImports.data).map((item) => {
                const ImportItemStateComponent = ImportItemStates[item.status];
                return (_jsx("div", { className: styles.info, children: _jsx("div", { className: styles.box, children: ImportItemStateComponent && (_jsx(ImportItemStateComponent, { item: item })) }) }, item.id));
            }) }));
};
export default ImportMediaProviderList;
