import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Loading } from 'src/ui/cosmos/Loading';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Alert } from 'src/ui/cosmosWeb/Alert';
import { useListDomains } from '../../../Domain/hooks/queries/useListDomains';
import MediaShareCopyToClipboard from '../../components/MediaShareCopyToClipboard';
const DefaultShare = ({ mediaCode, link, embedCode, onCopyLink, onCopyEmbedCode, }) => {
    const { data: domains, isLoading } = useListDomains({
        medias: [{ code: mediaCode }],
    });
    const hasRestrictions = domains?.length > 0;
    if (isLoading)
        return _jsx(Loading, { "data-testid": 'share-loading', full: true });
    return (_jsxs(_Fragment, { children: [hasRestrictions && (_jsx(Alert, { "data-testid": 'alert-restriction-information', context: 'info', children: _jsx(Trans, { i18nKey: 'share.restriction_information', components: {
                        button: (_jsx(Link, { to: `/library/media/${mediaCode}/restriction`, "data-testid": 'button-restriction-information' })),
                    } }) })), _jsx(MediaShareCopyToClipboard, { link: link, embedCode: embedCode, onCopyEmbedCode: onCopyEmbedCode, onCopyLink: onCopyLink })] }));
};
export default DefaultShare;
