import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from 'src/ui/cosmosWeb/Button';
import { Dropdown } from 'src/ui/cosmosWeb/DropDown';
import menuToolsSVG from 'public/assets/svg/toolsMenu/menu-tools.svg';
import { useMenuTools } from 'src/application/hooks/useMenuTools';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import styles from './styles.module.scss';
import { MenuItem } from './MenuItem';
import { EXTERNAL_URL_MENU_TOOLS_BUSINESS_WORKSPACE } from './constants';
export const ToolsMenu = () => {
    const { menuTools } = useMenuTools();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleOnClickMenuItem = ({ route, intentEventId, }) => {
        EventPrepAndTrackService.sendDebouncedInteractClick(intentEventId);
        if (typeof route === 'function') {
            route();
            return;
        }
        if (route.startsWith('http://') || route.startsWith('https://')) {
            window.open(route, '_blank');
            return;
        }
        navigate(route);
    };
    return (_jsx(_Fragment, { children: menuTools?.groups && (_jsxs(Dropdown, { className: styles.dropdown, position: 'end', children: [_jsx(Button, { slot: 'controls', variation: 'tertiary', className: styles.buttonDropdown, children: _jsx("div", { slot: 'icon', children: _jsx("img", { src: menuToolsSVG, alt: '' }) }) }), _jsxs("div", { slot: 'items', className: styles.dropdownContent, children: [_jsx("div", { className: '_p-4', children: menuTools.groups.map((group, index) => (_jsxs("div", { children: [_jsx("p", { className: '_mb-2 _pl-4 _text-black', children: t(`menu_tools.general.${group.title}`) }), _jsx("div", { className: styles.headerTools, children: group.items.map((item) => (_jsx(MenuItem, { ...item, title: t(item.title), onClick: () => handleOnClickMenuItem({
                                                route: item.route,
                                                intentEventId: item.dataHubEvent,
                                            }) }, item.title))) }), index < menuTools.groups.length - 1 && (_jsx("hr", { className: '_my-3 _border-gray-200' }))] }, group.title))) }), menuTools?.showAllToolsLink && (_jsx("div", { className: styles.allToolsContainer, children: _jsx("a", { href: EXTERNAL_URL_MENU_TOOLS_BUSINESS_WORKSPACE, rel: 'noopener noreferrer', onClick: () => EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.MENU_TOOLS), children: t('menu_tools.general.see_all_tools') }) }))] })] })) }));
};
