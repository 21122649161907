import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card } from 'src/ui/cosmos/Card';
import { CardBody } from 'src/ui/cosmos/Card/Body';
import { CardHeader } from 'src/ui/cosmos/Card/Header';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { useTranslation } from 'react-i18next';
import { PlanConsumption } from '../../containers/PlanConsumption';
import { AssetsTotalizer } from '../../containers/PlanConsumption/AssetsTotalizer';
import styles from './styles.module.scss';
const UsageResume = () => {
    const { t } = useTranslation();
    return (_jsxs(Card, { className: styles.container, children: [_jsx(CardHeader, { className: styles.header, children: _jsx(Heading, { type: 'h3', size: 'small', children: t('home.default_home.usage_resume_card.title') }) }), _jsxs(CardBody, { children: [_jsx(PlanConsumption, {}), _jsx(AssetsTotalizer, {})] })] }));
};
export default UsageResume;
