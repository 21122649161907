import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';
import { faGear, faInputText, faSignalSlash, faPlayPause, faSparkles, faTv, } from 'src/application/icons/fontAwesome/light';
import { AdvantageInfo } from '../AdvantagesInfo';
import styles from './styles.module.scss';
export const AdvantagesForYourStudents = () => {
    const { t } = useTranslation();
    const translatePrefix = 'home.welcome_home.advantages_card.advantages_modal.advantages_for_your_students';
    return (_jsxs("div", { className: styles.advantagesGroup, children: [_jsxs("div", { className: styles.row, children: [_jsx(AdvantageInfo, { icon: faSparkles, title: t('home.welcome_home.advantages_card.advantages_for_your_students.advantage1.title'), description: t(`${t('home.welcome_home.advantages_card.advantages_for_your_students.advantage1.description')}.`) }), _jsx(AdvantageInfo, { icon: faSignalSlash, title: t(`${translatePrefix}.advantage4.title`), description: t(`${translatePrefix}.advantage4.description`) })] }), _jsxs("div", { className: styles.row, children: [_jsx(AdvantageInfo, { icon: faGear, title: t(`${translatePrefix}.advantage1.title`), description: t(`${translatePrefix}.advantage1.description`) }), _jsx(AdvantageInfo, { icon: faPlayPause, title: t('home.welcome_home.advantages_card.advantages_for_your_students.advantage2.title'), description: t(`${t('home.welcome_home.advantages_card.advantages_for_your_students.advantage2.description')}.`) })] }), _jsxs("div", { className: styles.row, children: [_jsx(AdvantageInfo, { icon: faInputText, title: t(`${translatePrefix}.advantage2.title`), description: t(`${translatePrefix}.advantage2.description`) }), _jsx(AdvantageInfo, { icon: faTv, title: t(`${translatePrefix}.advantage3.title`), description: t(`${translatePrefix}.advantage3.description`) })] })] }));
};
