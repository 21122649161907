import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Pill } from 'src/ui/cosmosWeb/Pill';
import { LIBRARY_CONTENT_FILTER } from 'src/application/pages/Library/Interfaces/enum';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';
import { LibrarySearch } from '../LibrarySearch';
import styles from './styles.module.scss';
export const LibraryContentFilter = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const filter = searchParams.get('filter');
    const pathname = location.pathname;
    const search = location.search;
    const isAvalidFilter = filter && LIBRARY_CONTENT_FILTER[filter];
    const currentFilter = isAvalidFilter
        ? filter
        : LIBRARY_CONTENT_FILTER.all_files;
    const handleChangeFilter = useCallback((filter) => {
        const searchParams = new URLSearchParams(search);
        searchParams.set('filter', filter);
        searchParams.delete('page');
        navigate(`${pathname}?${searchParams}`);
    }, [navigate, pathname, search]);
    return (_jsxs("div", { className: styles.filter, children: [_jsx(LibrarySearch, {}), _jsx("div", { className: styles.pills, children: Object.keys(LIBRARY_CONTENT_FILTER).map((item) => (_jsx(Pill, { "data-testid": `library-content-filter-${item}`, className: styles.pill, active: currentFilter === LIBRARY_CONTENT_FILTER[item], onClick: () => handleChangeFilter(LIBRARY_CONTENT_FILTER[item]), children: t(`library.filter.${item}`) }, item))) })] }));
};
