import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import { Modal } from 'src/ui/cosmosWeb/Modal';
import { Button } from 'src/ui/cosmosWeb/Button';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEventImportProvider } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { NiceModal, useModal } from 'src/infrastructure/libs/NiceModal';
import styles from './styles.module.scss';
const FakeDoorModal = NiceModal.create(({ provider, title, description, buttons, loading, onDismiss, }) => {
    const modal = useModal();
    return (_jsxs(Modal, { className: styles.modal, onCloseModal: () => {
            onDismiss?.();
            modal.remove();
        }, opened: modal.visible, role: 'dialog', children: [_jsx(Heading, { className: styles.heading, slot: 'heading', size: 'small', children: title }), _jsx("div", { slot: 'description', children: _jsx(Paragraph, { className: styles.paragraph, size: 'small', children: description }) }), _jsx(Button, { className: styles.button, slot: 'controls', variation: buttons.action.color, context: buttons.action.context, loading: loading, role: 'button', onClick: () => {
                    modal.resolve(true);
                    modal.hide();
                    EventPrepAndTrackService.sendDebouncedInteractClick(IntentEventImportProvider.FAKEDOOR[provider].CONFIRMATION);
                }, children: buttons.action.title })] }));
});
export default FakeDoorModal;
