import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from 'src/application/icons/fontAwesome';
import { faCircleCheck } from 'src/application/icons/fontAwesome/light';
import { TextTruncate } from 'src/ui/TextTruncate';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
const Success = () => {
    const { t } = useTranslation();
    return (_jsxs("div", { className: styles.success, "data-testid": 'import-component-success', children: [_jsx("div", { className: styles.name, children: _jsx(TextTruncate, { children: t('upload.import.status.finished') }) }), _jsx("div", { className: styles.icon, children: _jsx(FontAwesomeIcon, { icon: faCircleCheck }) })] }));
};
export default Success;
