// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JmuoXSfdJoCWP2Rc_LY4{--hc-button-font-size: 1rem;--hc-button-line-height: 1.5rem;--hc-button-secondary-background-color: var(--hc-color-info-400);--hc-button-secondary-color: var(--white);--hc-button-secondary-hover-background-color: var(--hc-color-info-400);--hc-button-secondary-hover-color: var(--white);--hc-button-width: 100%;display:inherit}.peFmqNDgMj7ndxkx8W1w{--hc-h-small-md-strong-font-weight: 700;margin-left:.4375rem}.un3EtjbuELvtr0wgsEr7{--hc-modal-content-padding: 0 1.9375rem 1.9375rem;--hc-modal-dialog-width: 25.3125rem;--hc-modal-footer-padding: 0 1.9375rem 1.9375rem}.vnkiCAyO3iaVWPAhjvOB{color:var(--hc-color-primary-200)}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/MediaUpload/components/Modal/FakeDoorModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,2BAAA,CACA,+BAAA,CACA,gEAAA,CACA,yCAAA,CACA,sEAAA,CACA,+CAAA,CACA,uBAAA,CACA,eAAA,CAGF,sBACE,uCAAA,CACA,oBAAA,CAGF,sBACE,iDAAA,CACA,mCAAA,CACA,gDAAA,CAGF,sBACE,iCAAA","sourcesContent":[".button {\n  --hc-button-font-size: 1rem;\n  --hc-button-line-height: 1.5rem;\n  --hc-button-secondary-background-color: var(--hc-color-info-400);\n  --hc-button-secondary-color: var(--white);\n  --hc-button-secondary-hover-background-color: var(--hc-color-info-400);\n  --hc-button-secondary-hover-color: var(--white);\n  --hc-button-width: 100%;\n  display: inherit;\n}\n\n.heading {\n  --hc-h-small-md-strong-font-weight: 700;\n  margin-left: 0.4375rem;\n}\n\n.modal {\n  --hc-modal-content-padding: 0 1.9375rem 1.9375rem;\n  --hc-modal-dialog-width: 25.3125rem;\n  --hc-modal-footer-padding: 0 1.9375rem 1.9375rem;\n}\n\n.paragraph {\n  color: var(--hc-color-primary-200);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `JmuoXSfdJoCWP2Rc_LY4`,
	"heading": `peFmqNDgMj7ndxkx8W1w`,
	"modal": `un3EtjbuELvtr0wgsEr7`,
	"paragraph": `vnkiCAyO3iaVWPAhjvOB`
};
export default ___CSS_LOADER_EXPORT___;
