import { useQuery } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import MenuToolsService from 'src/infrastructure/services/MenuToolsService';
const STALE_TIME = 1000 * 20; // 20 seconds
export const useMenuTools = () => {
    const { data: menuTools } = useQuery({
        queryKey: [QueryKeys.MENU_TOOLS],
        queryFn: MenuToolsService.getMenuToolsItems,
        staleTime: STALE_TIME,
    });
    return { menuTools };
};
