import { useQuery } from 'src/application/hooks/useRequest';
import ImportMediaService from 'src/domain/Media/services/ImportMediaService';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const REFETCH_INTERVAL = 2 * 1000 * 60; // 2 minutes
export const useGetAllImports = () => {
    const { data } = useQuery({
        queryKey: [QueryKeys.IMPORT_PROVIDER],
        queryFn: async () => ImportMediaService.getLatestImport(),
        refetchInterval: REFETCH_INTERVAL,
    });
    return { data };
};
