import { jsx as _jsx } from "react/jsx-runtime";
import '@hotmart-org-ca/cosmos/dist/card/card.css';
import classNames from 'classnames';
export const Card = ({ withShadow = false, withBorder = false, withHover = false, withActiveShadow = false, className, children, }) => {
    return (_jsx("hot-card", { class: classNames({
            'hot-card--shadow': withShadow,
            'hot-card--bordered': withBorder,
            'hot-card--hover': withHover,
            'hot-card--active': withActiveShadow,
        }, className), "data-testid": 'card', children: children }));
};
