// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jQExQ8smcq6iBLu8Y3F7{display:flex;flex-direction:column;gap:.5rem}.jQExQ8smcq6iBLu8Y3F7 .c0R8xXCVv651UT0rn3SB{align-self:flex-end;margin-top:1.125rem}.jQExQ8smcq6iBLu8Y3F7>div{display:flex;flex-direction:column;justify-content:space-between}.jQExQ8smcq6iBLu8Y3F7>div label{color:var(--hc-color-neutral-900);font-weight:700;line-height:1.5rem;font-size:1rem;margin-bottom:.5rem}.jQExQ8smcq6iBLu8Y3F7>div label svg{margin-left:var(--hc-size-spacing-1)}.jQExQ8smcq6iBLu8Y3F7>div textarea{max-height:21.875rem}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Share/components/MediaShareCopyToClipboard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,SAAA,CAEA,4CACE,mBAAA,CACA,mBAAA,CAGF,0BACE,YAAA,CACA,qBAAA,CACA,6BAAA,CAEA,gCACE,iCAAA,CACA,eAAA,CACA,kBAAA,CACA,cAAA,CACA,mBAAA,CAEA,oCACE,oCAAA,CAIJ,mCACE,oBAAA","sourcesContent":[".contentField {\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n\n  .copyButton {\n    align-self: flex-end;\n    margin-top: 1.125rem;\n  }\n\n  & > div {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n\n    label {\n      color: var(--hc-color-neutral-900);\n      font-weight: 700;\n      line-height: 1.5rem;\n      font-size: 1rem;\n      margin-bottom: 0.5rem;\n\n      svg {\n        margin-left: var(--hc-size-spacing-1);\n      }\n    }\n\n    textarea {\n      max-height: 21.875rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentField": `jQExQ8smcq6iBLu8Y3F7`,
	"copyButton": `c0R8xXCVv651UT0rn3SB`
};
export default ___CSS_LOADER_EXPORT___;
