import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { List } from 'src/application/pages/Library/components/List';
import { Loading } from 'src/ui/cosmos/Loading';
import { ContentFeedback } from 'src/ui/ContentFeedback';
import { useSearchItems } from 'src/application/hooks/useSearchItems';
import { Breadcrumb } from 'src/ui/cosmos/Breadcrumb';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import searchWithNoResultImg from 'public/assets/svg/search_with_no_result.svg';
import { useMemo } from 'react';
import { Header } from '../Library/components/Header';
import { LIBRARY_CONTENT_FILTER } from '../Library/Interfaces/enum';
import { useSort } from '../Library/components/List/hooks/useSort';
import { REGISTERS_PER_PAGE_OPTIONS } from '../Library/components/List/constants';
import styles from './styles.module.scss';
const Search = () => {
    const [searchParams] = useSearchParams();
    const value = searchParams.get('value');
    const page = Number(searchParams.get('page'));
    const filter = searchParams.get('filter');
    const registerPerPage = Number(searchParams.get('registerPerPage'));
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const isAValidPage = page && !isNaN(page);
    const isAvalidRegisterPerPage = registerPerPage && REGISTERS_PER_PAGE_OPTIONS.includes(registerPerPage);
    const currentPage = isAValidPage ? page - 1 : 0;
    const currentRegisterPerPage = isAvalidRegisterPerPage
        ? registerPerPage
        : REGISTERS_PER_PAGE_OPTIONS[0];
    const category = useMemo(() => {
        const categoryMap = {
            [LIBRARY_CONTENT_FILTER.folders]: 'folder',
            [LIBRARY_CONTENT_FILTER.medias]: 'media',
        };
        return categoryMap[filter] || '';
    }, [filter]);
    const { direction, key, handleSorting } = useSort();
    const { data, isLoading, isPreviousData } = useSearchItems({
        name: value,
        page: currentPage,
        elementPerPage: currentRegisterPerPage,
        category,
        key: key,
        direction: direction,
    });
    const isEmptyResult = !data?.content || data?.content.length === 0;
    const shouldShowLoading = isLoading || isPreviousData;
    const handleChangePage = (page) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('page', page.toString());
        navigate(`/search?${searchParams}`);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Breadcrumb.Root, { children: _jsx(Breadcrumb.Item, { active: true, children: t('library.title') }) }), _jsxs("div", { className: styles.content, children: [_jsx(Header, { uploadButton: false }), shouldShowLoading && (_jsx("div", { className: styles.loadingAndEmptyResultWrapper, children: _jsx(Loading, {}) })), isEmptyResult && !shouldShowLoading && (_jsx("div", { className: styles.loadingAndEmptyResultWrapper, children: _jsx(ContentFeedback, { title: t('search.empty_result.title'), description: t('search.empty_result.description'), svg: searchWithNoResultImg }) })), !isEmptyResult && (_jsxs(_Fragment, { children: [_jsx(List.Root, { data: data.content, children: (table) => {
                                    return (_jsxs(_Fragment, { children: [_jsx(List.Header, { selectedItems: table
                                                    .getSelectedRowModel()
                                                    .flatRows.map((item) => item.original), areAllSelected: table.getIsAllPageRowsSelected(), handleToggleSelectAll: table.toggleAllPageRowsSelected, clearSelection: table.resetRowSelection, sorting: {
                                                    key,
                                                    direction,
                                                    onSort: handleSorting,
                                                } }), _jsx(List.Body, { children: table.getRowModel().rows.map((row) => (_jsx(List.Row, { item: row.original, isSelected: row.getIsSelected(), toggleSelect: row.toggleSelected }, row.original.code))) })] }));
                                } }), _jsx(List.Pagination, { totalPages: data.totalPages, currentPage: currentPage + 1, onPageChange: handleChangePage, registersPerPage: currentRegisterPerPage, totalCountOfItems: data.totalElements })] }))] })] }));
};
export default Search;
