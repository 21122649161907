import { useMutation, useQueryClient } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import MediaShareService from '../../services/MediaShareService';
export const useDeleteMediaShare = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ mediaCode }) => MediaShareService.deleteMediaShare(mediaCode),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QueryKeys.MEDIA_SHARE] });
        },
        onError: (error) => {
            console.error(error);
        },
    });
};
