// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ktqnbCjJnzrcgQMskW9f{padding:2.5rem 0;display:flex;flex-direction:column;gap:1.5rem}.ktqnbCjJnzrcgQMskW9f .B_evr4C1A5TPu6H9AtLD{display:grid;grid-template-columns:1fr 19.0625rem;column-gap:1.5rem}@media(max-width: 992px){.B_evr4C1A5TPu6H9AtLD{grid-template-columns:1fr;row-gap:1.5rem}}`, "",{"version":3,"sources":["webpack://./src/application/pages/Home/containers/WelcomeHome/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,YAAA,CACA,qBAAA,CACA,UAAA,CAEA,4CACE,YAAA,CACA,oCAAA,CACA,iBAAA,CAIJ,yBACI,sBACI,yBAAA,CACA,cAAA,CAAA","sourcesContent":[".container {\n  padding: 2.5rem 0;\n  display: flex;\n  flex-direction: column;\n  gap: 1.5rem;\n\n  .contentContainer {\n    display: grid;\n    grid-template-columns: 1fr 19.0625rem;\n    column-gap: 1.5rem;\n  }\n}\n\n@media (max-width: 992px) {\n    .contentContainer {\n        grid-template-columns: 1fr;\n        row-gap: 1.5rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ktqnbCjJnzrcgQMskW9f`,
	"contentContainer": `B_evr4C1A5TPu6H9AtLD`
};
export default ___CSS_LOADER_EXPORT___;
