export var FolderParamKey;
(function (FolderParamKey) {
    FolderParamKey["CODE"] = "code";
    FolderParamKey["NAME"] = "name";
    FolderParamKey["USER_MARKETPLACE_ID"] = "user_marketplace_id";
    FolderParamKey["CREATE_DATE"] = "create_date";
    FolderParamKey["UPDATE_DATE"] = "update_date";
})(FolderParamKey || (FolderParamKey = {}));
export var FolderParamDirection;
(function (FolderParamDirection) {
    FolderParamDirection["ASC"] = "asc";
    FolderParamDirection["DSC"] = "dsc";
})(FolderParamDirection || (FolderParamDirection = {}));
export var MEDIA_STATUS;
(function (MEDIA_STATUS) {
    MEDIA_STATUS["PROCESSING"] = "PROCESSING";
    MEDIA_STATUS["READY"] = "READY";
    MEDIA_STATUS["FAILED"] = "FAILED";
    MEDIA_STATUS["PENDING"] = "PENDING";
})(MEDIA_STATUS || (MEDIA_STATUS = {}));
