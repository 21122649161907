import { useCallback } from 'react';
import { useQuery } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import FoldersService from '../../services/FoldersService';
const useFolderLatest = ({ elementPerPage }) => {
    const getLatestFolders = useCallback(async () => {
        const data = await FoldersService.getLatestFolders(elementPerPage);
        return data;
    }, [elementPerPage]);
    return useQuery({
        queryKey: [QueryKeys.FOLDER_LATEST],
        queryFn: () => getLatestFolders(),
    });
};
export default useFolderLatest;
