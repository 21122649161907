export var ImportProvider;
(function (ImportProvider) {
    ImportProvider["GOOGLE_DRIVE"] = "GOOGLE_DRIVE";
    ImportProvider["PANDA"] = "PANDA";
    ImportProvider["VIMEO"] = "VIMEO";
    ImportProvider["YOUTUBE"] = "YOUTUBE";
})(ImportProvider || (ImportProvider = {}));
export var ImportStatus;
(function (ImportStatus) {
    ImportStatus["COMPLETED"] = "COMPLETED";
    ImportStatus["CREATED"] = "CREATED";
    ImportStatus["DISCOVERED"] = "DISCOVERED";
    ImportStatus["FAILED"] = "FAILED";
    ImportStatus["PROCESSING"] = "PROCESSING";
})(ImportStatus || (ImportStatus = {}));
export const ImportProviderName = {
    [ImportProvider.GOOGLE_DRIVE]: 'Google Drive',
    [ImportProvider.PANDA]: 'Panda Video',
    [ImportProvider.VIMEO]: 'Vimeo',
    [ImportProvider.YOUTUBE]: 'Youtube',
};
export const ImportProviderList = {
    media: [
        {
            name: ImportProviderName.VIMEO,
            provider: ImportProvider.VIMEO,
        },
        {
            name: ImportProviderName.PANDA,
            provider: ImportProvider.PANDA,
        },
    ],
    fakedoor: [
        {
            name: ImportProviderName.YOUTUBE,
            provider: ImportProvider.YOUTUBE,
        },
        {
            name: ImportProviderName.GOOGLE_DRIVE,
            provider: ImportProvider.GOOGLE_DRIVE,
        },
    ],
};
