import { useState } from 'react';
import { FolderParamDirection, FolderParamKey, } from 'src/infrastructure/endpoints/folder/enum';
export function useSort() {
    const [key, setKey] = useState(FolderParamKey.UPDATE_DATE);
    const [direction, setDirection] = useState(FolderParamDirection.DSC);
    const handleSorting = () => {
        if (key === FolderParamKey.UPDATE_DATE) {
            setKey(FolderParamKey.NAME);
        }
        else {
            if (direction === FolderParamDirection.DSC) {
                setDirection(FolderParamDirection.ASC);
            }
            else {
                setKey(FolderParamKey.UPDATE_DATE);
                setDirection(FolderParamDirection.DSC);
            }
        }
    };
    return {
        key,
        direction,
        handleSorting,
    };
}
