import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useAuth from 'src/application/hooks/useAuth';
import { Trans, useTranslation } from 'react-i18next';
import { WelcomeCard } from '../../components/WelcomeCard';
import LastFolders from '../LastFolders';
import LastUploads from '../LastUploads';
import Suggestions from '../Suggestions';
import UsageResume from '../../components/UsageResume';
import styles from './styles.module.scss';
export const DefaultHome = () => {
    const { t } = useTranslation();
    const { user } = useAuth();
    return (_jsxs("div", { className: styles.container, children: [_jsx(WelcomeCard, { title: _jsx(Trans, { i18nKey: 'general.hello_highlighted_name', values: { name: user.profile.name }, components: { strong: _jsx("strong", {}) } }), description: t('home.default_home.hello_access_info') }), _jsxs("div", { className: styles.content, children: [_jsxs("div", { className: styles.contentColumn, children: [_jsx(LastUploads, {}), _jsx(LastFolders, {})] }), _jsxs("div", { className: styles.contentColumn, children: [_jsx(UsageResume, {}), _jsx(Suggestions, {})] })] })] }));
};
