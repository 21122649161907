// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hYxEwsk0vM8Q9BbLBoTS{display:flex;gap:.5rem;justify-content:space-between}.hYxEwsk0vM8Q9BbLBoTS>div{flex:1;display:flex;flex-direction:column;font-size:var(--text-2)}.hYxEwsk0vM8Q9BbLBoTS>div .iovgaaApb57_zrqrT0yl{margin-bottom:var(--spacer-4)}`, "",{"version":3,"sources":["webpack://./src/application/pages/Home/containers/PlanConsumption/AssetsTotalizer/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,SAAA,CACA,6BAAA,CAEA,0BACE,MAAA,CACA,YAAA,CACA,qBAAA,CACA,uBAAA,CAEA,gDACE,6BAAA","sourcesContent":[".row {\n  display: flex;\n  gap: 0.5rem;\n  justify-content: space-between;\n\n  & > div {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    font-size: var(--text-2);\n\n    .rowTitle {\n      margin-bottom: var(--spacer-4);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `hYxEwsk0vM8Q9BbLBoTS`,
	"rowTitle": `iovgaaApb57_zrqrT0yl`
};
export default ___CSS_LOADER_EXPORT___;
