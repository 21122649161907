import { useQueryClient, useMutation } from '@tanstack/react-query';
import ImportMediaService from 'src/domain/Media/services/ImportMediaService';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
export const useRetryFailedImport = () => {
    const queryClient = useQueryClient();
    const { mutate: importReprocess } = useMutation({
        mutationFn: async ({ item }) => ImportMediaService.retryFailedImport({ item }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.IMPORT_PROVIDER],
            });
        },
    });
    return { importReprocess };
};
