// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LooPYe0QZgQgo9cVfo3u{--progress-bg: var(--blue)}.jXWujIjKmRFpxYcT8lvs{--progress-bg: var(--blue)}.IytbydveGOE0yZvAPn5J{--progress-bg: var(--red)}.m4u8itYUuIS8hlswg3rF{height:.25rem}.LbioHVjqiQ3N_2Uw7Lyg{height:.5rem}.BHUYoOO7DPqfF4kMnXgI{height:.75rem}`, "",{"version":3,"sources":["webpack://./src/ui/cosmos/Progress/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,0BAAA,CAGF,sBACE,0BAAA,CAGF,sBACE,yBAAA,CAGF,sBACE,aAAA,CAGF,sBACE,YAAA,CAGF,sBACE,aAAA","sourcesContent":[".warning {\n  --progress-bg: var(--blue);\n}\n\n.success {\n  --progress-bg: var(--blue);\n}\n\n.danger {\n  --progress-bg: var(--red);\n}\n\n.small {\n  height: 0.25rem;\n}\n\n.default {\n  height: 0.5rem;\n}\n\n.large {\n  height: 0.75rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warning": `LooPYe0QZgQgo9cVfo3u`,
	"success": `jXWujIjKmRFpxYcT8lvs`,
	"danger": `IytbydveGOE0yZvAPn5J`,
	"small": `m4u8itYUuIS8hlswg3rF`,
	"default": `LbioHVjqiQ3N_2Uw7Lyg`,
	"large": `BHUYoOO7DPqfF4kMnXgI`
};
export default ___CSS_LOADER_EXPORT___;
