import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'src/ui/cosmosWeb/Button';
import { TextTruncate } from 'src/ui/TextTruncate';
import { useTranslation } from 'react-i18next';
import FakeDoorModal from 'src/domain/Media/modules/MediaUpload/components/Modal/FakeDoorModal';
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import { NiceModal } from 'src/infrastructure/libs/NiceModal';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEventImportProvider } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { ImportMediaProviderModal } from '../ImportMediaProviderModal';
import { UploadAndImportModal } from '../UploadAndImportModal';
import styles from './styles.module.scss';
import { ImportProviderList } from './constants';
export const ImportMediaProvider = () => {
    const { t } = useTranslation();
    return (_jsxs("div", { className: styles.container, slot: 'controls', children: [_jsx(TextTruncate, { children: _jsx(Paragraph, { size: 'small', className: styles.paragraph, children: t('library.modal.upload_and_import.controls') }) }), _jsxs("div", { className: styles.button, children: [ImportProviderList.media.map(({ provider, name }) => (_jsx(Button, { slot: 'icon', variation: 'secondary', context: 'brand', size: 'small', onClick: () => {
                            NiceModal.show(ImportMediaProviderModal, {
                                provider,
                            });
                            NiceModal.hide(UploadAndImportModal);
                            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEventImportProvider.IMPORT[provider].BUTTON);
                        }, children: t('library.modal.upload_and_import.buttons.provider', {
                            name: name,
                        }) }, name))), ImportProviderList.fakedoor.map(({ provider, name }) => (_jsx(Button, { slot: 'icon', variation: 'secondary', context: 'brand', size: 'small', onClick: () => {
                            NiceModal.show(FakeDoorModal, {
                                provider,
                                title: t('library.modal.fakedoor.title', {
                                    name: name,
                                }),
                                description: t('library.modal.fakedoor.description', {
                                    name: name,
                                }),
                                buttons: {
                                    action: {
                                        context: 'brand',
                                        title: t('library.modal.fakedoor.button'),
                                    },
                                },
                            });
                            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEventImportProvider.FAKEDOOR[provider].BUTTON);
                        }, children: t('library.modal.upload_and_import.buttons.provider', {
                            name: name,
                        }) }, name)))] })] }));
};
