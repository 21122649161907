// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rksfU99J1_ZoVlcSz53b{display:flex;flex-direction:column;gap:1rem}.rksfU99J1_ZoVlcSz53b .bti6ERUdwNrTai5MSoJ3{display:flex;justify-content:space-between;gap:3rem}`, "",{"version":3,"sources":["webpack://./src/application/pages/Home/containers/WelcomeHome/AdvantagesModal/AdvantagesForYourStudents/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,4CACI,YAAA,CACA,6BAAA,CACA,QAAA","sourcesContent":[".advantagesGroup {\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n\n    .row {\n        display: flex;\n        justify-content: space-between;\n        gap: 3rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"advantagesGroup": `rksfU99J1_ZoVlcSz53b`,
	"row": `bti6ERUdwNrTai5MSoJ3`
};
export default ___CSS_LOADER_EXPORT___;
