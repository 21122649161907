import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from 'src/ui/cosmosWeb/Modal';
import { Button } from 'src/ui/cosmosWeb/Button';
import { useTranslation } from 'react-i18next';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { Nav } from 'src/ui/cosmos/Nav';
import { NavItem } from 'src/ui/cosmos/Nav/NavItem';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { AdvantagesForYourStudents } from './AdvantagesForYourStudents';
import { AdvantagesForYou } from './AdvantagesForYou';
import styles from './styles.module.scss';
export const AdvantagesModal = NiceModal.create(() => {
    const modal = useModal();
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState(0);
    const tabs = [
        {
            id: 0,
            title: t('home.welcome_home.advantages_card.advantages_for_you.title'),
            component: _jsx(AdvantagesForYou, {}),
            event: IntentEvent.ADVANTAGES_HOTMART_PLAYER_TAB_ADVANTAGES_FOR_YOU,
        },
        {
            id: 1,
            title: t('home.welcome_home.advantages_card.advantages_for_your_students.title'),
            component: _jsx(AdvantagesForYourStudents, {}),
            event: IntentEvent.ADVANTAGES_HOTMART_PLAYER_TAB_ADVANTAGES_FOR_YOUR_STUDENTS,
        },
    ];
    const handleTabChange = (tab) => {
        setCurrentTab(tab.id);
        EventPrepAndTrackService.sendDebouncedInteractClick(tab.event);
    };
    return (_jsxs(Modal, { onCloseModal: () => {
            modal.remove();
            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.HOME_CLOSE_ADVANTAGES_HOTMART_PLAYER);
        }, className: styles.modal, opened: modal.visible, "data-testid": 'advantages-modal', children: [_jsxs(Heading, { className: styles.heading, slot: 'heading', size: 'small', children: [t('home.welcome_home.advantages_card.advantages_modal.title'), _jsx("p", { children: t('home.welcome_home.advantages_card.advantages_modal.description') })] }), _jsxs("div", { slot: 'description', children: [_jsx(Nav, { type: 'tabs', children: tabs.map((tab) => (_jsx(NavItem, { active: tab.id === currentTab, onClick: () => handleTabChange(tab), children: tab.title }, tab.title))) }), _jsx("div", { className: styles.content, children: tabs[currentTab].component }), _jsx("div", { className: styles.footer, children: _jsx(Button, { slot: 'controls', onClick: modal.hide, children: t('home.welcome_home.advantages_card.advantages_modal.button') }) })] })] }));
});
