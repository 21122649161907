import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import { useTranslation } from 'react-i18next';
import importFolder from 'public/assets/svg/import_folder.svg';
import styles from './styles.module.scss';
export const FirstStep = () => {
    const { t } = useTranslation();
    return (_jsxs("div", { className: styles.stepContainer, children: [_jsxs("div", { className: styles.instructions, children: [_jsx(Heading, { size: 'nano', strong: true, children: t('library.modal.import_tutorial.step1.title') }), _jsxs("ul", { children: [_jsx("li", { children: t('library.modal.import_tutorial.step1.item1') }), _jsx("li", { children: t('library.modal.import_tutorial.step1.item2') })] }), _jsx(Paragraph, { size: 'nano', className: styles.note, children: t('library.modal.import_tutorial.step1.note') })] }), _jsx("div", { className: styles.illustration, children: _jsx("img", { src: importFolder, alt: t('library.modal.import_tutorial.step1.title'), width: 200, height: 200 }) })] }));
};
