import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { useMediaData } from 'src/domain/Media/hooks';
import { MediaThumb } from 'src/ui/MediaThumb';
import { TextTruncate } from 'src/ui/TextTruncate';
import ContentLoader from 'src/ui/cosmos/ContentLoader';
import styles from './styles.module.scss';
const MediaItem = ({ mediaCode }) => {
    const { data: media, isLoading } = useMediaData({ mediaCode });
    if (isLoading)
        return _jsx(ContentLoader, { className: styles.contentLoader });
    return (_jsxs(Link, { to: `/library/media/${mediaCode}/share`, className: styles.container, children: [_jsx(MediaThumb, { mediaCode: mediaCode, size: 'xsmall', showPlayIcon: false }), _jsx(TextTruncate, { children: media.name })] }));
};
export default MediaItem;
