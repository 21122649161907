import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'src/ui/cosmosWeb/Button';
import classNames from 'classnames';
import { Tag } from 'src/ui/cosmosWeb/Tag';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
export const MenuItem = ({ title, icon, bgColor, isBeta, onClick, }) => {
    const { t } = useTranslation();
    return (_jsxs(Button, { className: classNames(styles.menuItem, '_position-relative'), variation: 'tertiary', onClick: onClick, children: [isBeta && (_jsx(Tag, { context: 'danger', className: classNames(styles.tag, '_position-absolute'), children: t('menu_tools.general.beta') })), _jsx("span", { className: classNames(styles[bgColor], '_p-4 _rounded-lg _mb-1'), children: _jsx("img", { src: icon, alt: title, width: 32, height: 32 }) }), _jsx("span", { className: classNames(styles.buttonTitle, '_flex-1'), children: title })] }));
};
