import { useQuery } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import FoldersService from 'src/domain/Folder/services/FoldersService';
export const useSearchItems = ({ name, page, elementPerPage, category, key, direction, }) => {
    return useQuery({
        queryKey: [
            QueryKeys.SEARCH,
            name,
            page,
            category,
            key,
            direction,
            elementPerPage,
        ],
        queryFn: () => FoldersService.searchItems({
            name,
            page,
            elementPerPage,
            category,
            key,
            direction,
        }),
    });
};
