import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { TextTruncate } from 'src/ui/TextTruncate';
import { Modal } from 'src/ui/cosmosWeb/Modal';
import { useTranslation } from 'react-i18next';
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import { useRef, useState } from 'react';
import { INITIAL_FILE_CONFIG_ALLOWED } from 'src/domain/Media/modules/MediaUpload/contexts/MediaUploadContext/constants';
import { useFileUploadProcess } from 'src/domain/Media/modules/MediaUpload/contexts/MediaUploadContext/hooks/useFileUploadProcess';
import { GeneralStatus } from 'src/domain/Media/modules/MediaUpload/interfaces/FileUploadProps';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { ImportMediaProvider } from 'src/domain/Media/components/UploadAndImport/ImportMediaProvider';
import { convertSizeUnity, oneGbInBytes } from 'src/domain/User/Plan/utils';
import { NiceModal, useModal } from 'src/infrastructure/libs/NiceModal';
import styles from './styles.module.scss';
export const UploadAndImportModal = NiceModal.create(({ onDismiss, folderCode = '' }) => {
    const modal = useModal();
    const { t } = useTranslation();
    const { onSelectFiles, generalUploadState } = useFileUploadProcess();
    const hasPendingFile = generalUploadState === GeneralStatus.UPLOADING;
    const [files, setFiles] = useState('');
    const inputFileRef = useRef(null);
    return (_jsxs(Modal, { onCloseModal: () => {
            modal.remove();
            onDismiss?.();
        }, className: styles.modal, opened: modal.visible, dismissible: true, "data-testid": 'import-options-modal', children: [_jsx("div", { className: styles.modalHeader, slot: 'heading', children: _jsx(TextTruncate, { children: _jsx(Heading, { size: 'small', children: t('library.modal.upload_and_import.title') }) }) }), _jsxs("div", { className: styles.modalBody, slot: 'description', children: [_jsx(Paragraph, { size: 'small', children: t('library.modal.upload_and_import.description', {
                            fileTypes: INITIAL_FILE_CONFIG_ALLOWED.allowedFiles.join(', '),
                            maxSize: convertSizeUnity(INITIAL_FILE_CONFIG_ALLOWED.byteSizeLimit / oneGbInBytes),
                        }) }), _jsxs("div", { className: styles.uploadFile, onClick: () => {
                            inputFileRef.current?.click();
                            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_NEW_UPLOAD);
                        }, children: [_jsx("span", { children: t('library.modal.upload_and_import.upload.upload_text') }), _jsx("input", { ref: inputFileRef, "data-testid": 'input-file', accept: INITIAL_FILE_CONFIG_ALLOWED.supportedFiles.join(','), type: 'file', multiple: true, disabled: hasPendingFile, value: files, onChange: (e) => {
                                    onSelectFiles(e.target.files, folderCode);
                                    setFiles('');
                                    modal.hide();
                                } })] })] }), _jsx(ImportMediaProvider, {})] }));
});
