import { resources } from './resources';
export const config = {
    fallbackLng: 'en',
    load: 'currentOnly',
    debug: process.env.TARGET_ENV === 'development',
    resources,
    supportedLngs: ['en', 'es', 'pt-BR'],
    interpolation: {
        escapeValue: false,
    },
};
