import { useMutation, useQueryClient } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import ImportMediaService from 'src/domain/Media/services/ImportMediaService';
export const useImportKeyValidation = () => {
    const queryClient = useQueryClient();
    const { data, mutateAsync } = useMutation({
        mutationFn: async ({ provider, token }) => ImportMediaService.validateImportKey({ provider, token }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.IMPORT_PROVIDER],
            });
        },
    });
    return { data, mutateAsync };
};
