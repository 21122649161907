import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from 'src/application/icons/fontAwesome';
import { faCircleSmall, faCircleDot, } from 'src/application/icons/fontAwesome/regular';
import { faCircleSmall as fasCircleSmall } from 'src/application/icons/fontAwesome/solid';
import styles from './styles.module.scss';
const StepByStep = ({ active, steps }) => {
    return (_jsx("div", { className: styles.stepByStep, children: steps.map((title, index) => {
            let itemStyle = styles.previous;
            let icon = fasCircleSmall;
            if (active === index) {
                itemStyle = styles.active;
                icon = faCircleDot;
            }
            if (active < index) {
                itemStyle = styles.next;
                icon = faCircleSmall;
            }
            return (_jsxs("div", { className: itemStyle, children: [_jsx("hr", { className: styles.lineLeft }), _jsx("hr", { className: styles.lineRight }), _jsx(FontAwesomeIcon, { icon: icon }), _jsx("span", { children: title })] }, index));
        }) }));
};
export default StepByStep;
