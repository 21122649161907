import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { MediaThumb } from 'src/ui/MediaThumb';
import { MEDIA_STATUS } from 'src/infrastructure/endpoints/folder/enum';
import ContentLoader from 'src/ui/cosmos/ContentLoader';
import classNames from 'classnames';
import styles from './styles.module.scss';
const MediaItem = ({ media, ...rest }) => {
    if (media.status !== MEDIA_STATUS.READY)
        return (_jsx("div", { className: classNames(styles.container, styles.inProgress), ...rest, children: _jsxs("div", { className: styles.content, children: [_jsx(ContentLoader, { className: styles.thumb, "data-testid": 'thumbnail-content-loader' }), _jsx("strong", { className: styles.name, children: media.name ?? _jsx(ContentLoader, {}) })] }) }));
    return (_jsx("div", { className: styles.container, ...rest, children: _jsxs(Link, { to: `/library/media/${media.code}`, className: styles.content, children: [_jsx(MediaThumb, { className: styles.thumb, mediaCode: media.code, size: 'small' }), _jsx("strong", { className: styles.name, children: media.name })] }) }));
};
export default MediaItem;
