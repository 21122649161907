// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QuoL5bgzi8TsHPnDkCE4{display:flex;align-items:center;gap:1rem;border:1px solid var(--gray-200);border-radius:.5rem;overflow:hidden}.dzGsLWhE_5uk9puhaqBk{width:100%;height:100%;min-height:3.25rem}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Share/container/LimitedShare/components/MediaItem/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,gCAAA,CACA,mBAAA,CACA,eAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,kBAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n  border: 1px solid var(--gray-200);\n  border-radius: 0.5rem;\n  overflow: hidden;\n}\n\n.contentLoader {\n  width: 100%;\n  height: 100%;\n  min-height: 3.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `QuoL5bgzi8TsHPnDkCE4`,
	"contentLoader": `dzGsLWhE_5uk9puhaqBk`
};
export default ___CSS_LOADER_EXPORT___;
