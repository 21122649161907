// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.I7cnF4KrER2r_Zov3_mQ{padding:1.5rem;gap:1rem;padding-bottom:2.875rem}.I7cnF4KrER2r_Zov3_mQ .ZrdkDASqEi4WxFFKgV_N{display:flex;justify-content:space-between;align-items:center;gap:1rem;--hc-h-margin: 0}`, "",{"version":3,"sources":["webpack://./src/application/pages/Home/components/UsageResume/styles.module.scss","webpack://./src/application/pages/Home/styles/home-card.scss"],"names":[],"mappings":"AAEA,sBCDE,cAAA,CACA,QAAA,CDEA,uBAAA,CAEA,4CAAA,YAAA,CCCA,6BAAA,CACA,kBAAA,CACA,QAAA,CACA,gBAAA","sourcesContent":["@import '../../styles/home-card.scss';\n\n.container {\n  @include home-card;\n  padding-bottom: 2.875rem;\n\n  .header {\n    @include home-card-header;\n  }\n}\n","@mixin home-card {\n  padding: 1.5rem;\n  gap: 1rem;\n}\n\n@mixin home-card-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 1rem;\n  --hc-h-margin: 0;\n}\n\n@mixin home-card-header-actions {\n  display: flex;\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `I7cnF4KrER2r_Zov3_mQ`,
	"header": `ZrdkDASqEi4WxFFKgV_N`
};
export default ___CSS_LOADER_EXPORT___;
