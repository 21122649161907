import * as MediaEndpoint from 'src/infrastructure/endpoints/media';
class MediaService {
    async download({ code }) {
        return MediaEndpoint.download({
            code,
        });
    }
    async update({ code, name, language, isPublic }) {
        return MediaEndpoint.update({
            code,
            name,
            language,
            isPublic,
        });
    }
    async getMediasStatusByDateInterval(data) {
        return (await MediaEndpoint.getMediasStatusByDateInterval(data)).map((mediaItem) => {
            const { folder_code, ...media } = mediaItem;
            return {
                ...media,
                folderCode: folder_code,
            };
        });
    }
    async getSignature({ code }) {
        return MediaEndpoint.getSignature({ code });
    }
    async getMediaDataByMediaCode(code) {
        return MediaEndpoint.getMediaDataByMediaCode(code);
    }
    async getMediaCount() {
        return MediaEndpoint.getMediaCount();
    }
    async getMediaDurationCount() {
        return MediaEndpoint.getMediaDurationCount();
    }
    async getLatestMedias(amount) {
        return MediaEndpoint.getLatestMedias(amount);
    }
}
export default new MediaService();
