import Api from 'src/infrastructure/services/Api';
export const getMediasShare = async () => {
    const response = await Api.call({
        method: 'GET',
        url: `${process.env.API_GATEWAY}/media-share`,
    });
    return response.data;
};
export const addMediaShare = async (mediaCode) => {
    const response = await Api.call({
        method: 'POST',
        url: `${process.env.API_GATEWAY}/media-share/share/${mediaCode}`,
    });
    return response.data;
};
export const deleteMediaShare = async (mediaCode) => {
    const response = await Api.call({
        method: 'DELETE',
        url: `${process.env.API_GATEWAY}/media-share/delete/${mediaCode}`,
    });
    return response.data;
};
