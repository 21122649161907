import { useStartImport } from './useStartImport';
import { useRetryFailedImport } from './useRetryFailedImport';
import { useGetAllImports } from './useGetAllImports';
import { useImportKeyValidation } from './useImportKeyValidation';
export const useMediaImports = () => {
    const getAllImports = useGetAllImports();
    const retryFailedImport = useRetryFailedImport();
    const startImport = useStartImport();
    const importKeyValidation = useImportKeyValidation();
    return {
        getAllImports,
        retryFailedImport,
        startImport,
        importKeyValidation,
    };
};
