import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'src/ui/cosmosWeb/Button';
import { TextTruncate } from 'src/ui/TextTruncate';
import { useTranslation } from 'react-i18next';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { useMediaImports } from 'src/domain/Media/hooks';
import { IntentEventImportProvider } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import styles from './styles.module.scss';
const Failed = ({ item }) => {
    const { t } = useTranslation();
    const { retryFailedImport } = useMediaImports();
    return (_jsxs("div", { className: styles.error, "data-testid": 'import-component-failed', children: [_jsx("div", { className: styles.name, children: _jsx(TextTruncate, { children: _jsx("div", { className: styles.title, children: t('upload.import.error.title', {
                            quantity: item.failed,
                            amount: item.total,
                        }) }) }) }), _jsxs("div", { className: styles.message, children: [t('upload.import.error.message'), _jsx(Button, { className: styles.button, context: 'danger', variation: 'tertiary', "data-testid": 'button-retry-import', onClick: () => {
                            retryFailedImport.importReprocess({ item });
                            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEventImportProvider.IMPORT[item.service].RETRY);
                        }, children: t('upload.import.error.try_again') })] })] }));
};
export default Failed;
