import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
export const NavItem = ({ active = false, disabled = false, className, onClick, children, }) => {
    return (_jsx("hot-nav-item", { class: classNames({
            ['hot-nav__item--active']: active,
            ['hot-nav__item--disabled']: disabled,
        }, className), 
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClick: !disabled ? onClick : () => { }, children: children }));
};
