import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { Card } from 'src/ui/cosmos/Card';
import { CardBody } from 'src/ui/cosmos/Card/Body';
import { CardHeader } from 'src/ui/cosmos/Card/Header';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide, Autoplay, Navigation, Pagination, } from 'src/infrastructure/libs/Swiper';
import { SuggestionSlide } from './SuggestionSlide';
import styles from './styles.module.scss';
import useSuggestions from './hooks/useSuggestions';
const Suggestions = () => {
    const { t } = useTranslation();
    const suggestions = useSuggestions();
    return (_jsxs(Card, { className: styles.container, children: [_jsx(CardHeader, { className: styles.header, children: _jsx(Heading, { type: 'h3', size: 'small', children: t('home.default_home.suggestions_card.title') }) }), _jsx(CardBody, { children: _jsx(Swiper, { spaceBetween: 5, centeredSlides: true, cssMode: true, autoplay: {
                        delay: 15000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                    }, loop: true, pagination: {
                        clickable: true,
                    }, navigation: true, modules: [Autoplay, Pagination, Navigation], className: styles.swiper, children: suggestions.map((item) => (_jsx(SwiperSlide, { className: styles.swiperSlide, children: _jsx(SuggestionSlide, { title: item.text, smallText: item.smallText, action: item.action }) }, String(item.id)))) }) })] }));
};
export default Suggestions;
