// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.a8xJx84Vio6Y9qP6Qe05{display:flex;width:100%;justify-content:space-between;margin-bottom:1.5rem}.a8xJx84Vio6Y9qP6Qe05 .hFcMpy7isBcOetCjLmOA .nv8Z_xlqd2Z2oOuvWMkH{--hc-dropdown-header-box-shadow: none;--hc-dropdown-header-border-bottom: none;--hc-dropdown-header-label-font-size: var(--hc-size-text-small);color:var(--gray-500)}`, "",{"version":3,"sources":["webpack://./src/application/pages/Folder/components/Header/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CACA,6BAAA,CACA,oBAAA,CAGE,kEACE,qCAAA,CACA,wCAAA,CACA,+DAAA,CACA,qBAAA","sourcesContent":[".container {\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n  margin-bottom: 1.5rem;\n\n  .dropdown {\n    .dropdownHeaderItem {\n      --hc-dropdown-header-box-shadow: none;\n      --hc-dropdown-header-border-bottom: none;\n      --hc-dropdown-header-label-font-size: var(--hc-size-text-small);\n      color: var(--gray-500);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `a8xJx84Vio6Y9qP6Qe05`,
	"dropdown": `hFcMpy7isBcOetCjLmOA`,
	"dropdownHeaderItem": `nv8Z_xlqd2Z2oOuvWMkH`
};
export default ___CSS_LOADER_EXPORT___;
