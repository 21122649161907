import convertSize from 'convert-size';
const DANGER_STATUS_NUMBER = 80;
export const storageStatus = (used) => {
    if (used > DANGER_STATUS_NUMBER)
        return 'danger';
    return 'success';
};
export const convertSizeUnity = (gigabytes = 0) => {
    if (gigabytes < 0.99) {
        return convertSize(`${gigabytes} GB`, 'MB', {
            stringify: true,
            accuracy: 2,
        });
    }
    if (gigabytes > 999) {
        return convertSize(`${gigabytes} GB`, 'TB', {
            stringify: true,
            accuracy: 2,
        });
    }
    return `${gigabytes} GB`;
};
export const oneGbInBytes = 1073741824;
