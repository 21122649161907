import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HotProgress } from 'src/ui/cosmos/Progress';
import { TextTruncate } from 'src/ui/TextTruncate';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
const Progress = ({ item }) => {
    const { t } = useTranslation();
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const percentage = (item.completed / item.total) * 100;
    return (_jsxs("div", { className: styles.progress, "data-testid": 'import-component-progress', children: [_jsx("div", { className: styles.name, children: _jsx(TextTruncate, { children: _jsx("div", { className: styles.title, children: t('upload.import.status.progress', {
                            quantity: item.completed,
                            amount: item.total,
                        }) }) }) }), _jsx("div", { className: styles.progressBar, children: _jsx(HotProgress, { value: percentage ?? 0, color: 'success', thickness: 'small' }) })] }));
};
export default Progress;
