// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YPbd_k0tBz9dbmhJNObz{--hc-modal-dialog-width: 50.625rem;--hc-modal-dialog-max-width: 50.625rem}._Ci7uRvZTwxfII7Fr0vN{--hc-p-neutral-color: var(--neutral-900)}._7TAxH894oFJrNJ0UsTC,.bqzJD8yDckP2wV7sC0vx,.OUp3RXG2riPUKdaJsGYe{width:100%}.OUp3RXG2riPUKdaJsGYe{display:flex}.SwQy6RrbHm_wMWfpSTVE{width:100%}.SwQy6RrbHm_wMWfpSTVE .aXJFmUqZiWfPiqhBzG2O{margin-left:1rem}.b9w5Eka7kmXnzvIA7wwr{padding-top:.5rem;max-width:22rem}.vNrHwUZTjH6wq51aMTx7,.p3MAW9sclMvECHAJExU8{margin-left:auto}`, "",{"version":3,"sources":["webpack://./src/domain/Media/components/UploadAndImport/ImportMediaProviderModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kCAAA,CACA,sCAAA,CAEF,sBACE,wCAAA,CAGF,kEACE,UAAA,CAGF,sBACE,YAAA,CAGF,sBACE,UAAA,CAEA,4CACE,gBAAA,CAIJ,sBACE,iBAAA,CACA,eAAA,CAGF,4CACE,gBAAA","sourcesContent":[".modal {\n  --hc-modal-dialog-width: 50.625rem;\n  --hc-modal-dialog-max-width: 50.625rem;\n}\n.subtitle {\n  --hc-p-neutral-color: var(--neutral-900);\n}\n\n.modalHeader, .modalBody, .modalFooter {\n  width: 100%;\n}\n\n.modalFooter {\n  display: flex;\n}\n\n.buttons {\n  width: 100%;\n\n  .skipButton {\n    margin-left: 1rem;\n  }\n}\n\n.inputKey {\n  padding-top: .5rem;\n  max-width: 22rem;\n}\n\n.nextButton, .importButton {\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `YPbd_k0tBz9dbmhJNObz`,
	"subtitle": `_Ci7uRvZTwxfII7Fr0vN`,
	"modalHeader": `_7TAxH894oFJrNJ0UsTC`,
	"modalBody": `bqzJD8yDckP2wV7sC0vx`,
	"modalFooter": `OUp3RXG2riPUKdaJsGYe`,
	"buttons": `SwQy6RrbHm_wMWfpSTVE`,
	"skipButton": `aXJFmUqZiWfPiqhBzG2O`,
	"inputKey": `b9w5Eka7kmXnzvIA7wwr`,
	"nextButton": `vNrHwUZTjH6wq51aMTx7`,
	"importButton": `p3MAW9sclMvECHAJExU8`
};
export default ___CSS_LOADER_EXPORT___;
