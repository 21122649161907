import { jsx as _jsx } from "react/jsx-runtime";
import { Loading } from 'src/ui/cosmos/Loading';
import { Navigate } from 'react-router-dom';
import useAccounts from '../../hooks/useAccounts';
import styles from './styles.module.scss';
const ManagerAccount = ({ children }) => {
    const { isLoading, data: accounts, selectAccount, isAValidLocalStorageAccountId, } = useAccounts();
    if (isLoading)
        return (_jsx("div", { className: styles.container, children: _jsx(Loading, { full: true }) }));
    if (isAValidLocalStorageAccountId())
        return children;
    if (accounts?.length === 1) {
        selectAccount(accounts[0].accountOwnerMarketplaceId);
        return _jsx(Navigate, { to: '/', replace: true });
    }
    else {
        return _jsx(Navigate, { to: '/accounts', replace: true });
    }
};
export default ManagerAccount;
