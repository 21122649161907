import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { FontAwesomeIcon } from 'src/application/icons/fontAwesome';
import { Tag } from 'src/ui/cosmosWeb/Tag';
import { faCheckCircle } from 'src/application/icons/fontAwesome/regular';
import useCopyToClipboard from 'src/application/hooks/useCopyToClipboard';
import { getMediaEmbed, getMediaLink } from 'src/domain/Media/utils/shareLink';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useAccounts from 'src/domain/User/Accounts/hooks/useAccounts';
import styles from './styles.module.scss';
import DefaultShare from './DefaultShare';
import LimitedShare from './LimitedShare';
const ShareMedia = () => {
    const { t } = useTranslation();
    const { mediaCode } = useParams();
    const link = getMediaLink(mediaCode);
    const embedCode = getMediaEmbed(mediaCode);
    const { copyToClipboard, isCopied } = useCopyToClipboard();
    const [copyType, setCopyType] = useState('');
    const { currentAccount } = useAccounts();
    const isFreemium = currentAccount.freemium;
    const copyMessage = `${copyType} ${t('share.copy_message')}`;
    const handleCopyLink = () => {
        setCopyType(t('share.link'));
        copyToClipboard(link);
        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.SETTINGS_MEDIA_SHARE_COPY_LINK);
    };
    const handleCopyEmbedCode = () => {
        setCopyType(t('share.embed_code'));
        copyToClipboard(embedCode);
        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.SETTINGS_MEDIA_SHARE_COPY_EMBED);
    };
    return (_jsxs("div", { className: styles.container, children: [isCopied && (_jsxs(Tag, { context: 'success', className: styles.copyTag, shadow: 'small', size: 'large', "data-testid": 'copy-success-alert', children: [_jsx("div", { slot: 'icon', children: _jsx(FontAwesomeIcon, { icon: faCheckCircle, width: 40, height: 40 }) }), copyMessage] })), isFreemium ? (_jsx(LimitedShare, { mediaCode: mediaCode, link: link, embedCode: embedCode, onCopyLink: handleCopyLink, onCopyEmbedCode: handleCopyEmbedCode })) : (_jsx(DefaultShare, { mediaCode: mediaCode, link: link, embedCode: embedCode, onCopyLink: handleCopyLink, onCopyEmbedCode: handleCopyEmbedCode }))] }));
};
export default ShareMedia;
