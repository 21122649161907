import { useMutation, useQueryClient } from 'src/application/hooks/useRequest';
import ImportMediaService from 'src/domain/Media/services/ImportMediaService';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
export const useStartImport = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({ provider, token }) => ImportMediaService.startImport({ provider, token }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.IMPORT_PROVIDER],
            });
        },
    });
};
