import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { FontAwesomeIcon } from 'src/application/icons/fontAwesome';
import { faArrowDown, faArrowUp, } from 'src/application/icons/fontAwesome/light';
import { FolderParamDirection } from 'src/infrastructure/endpoints/folder/enum';
import styles from './styles.module.scss';
export function SortingButton({ hideIcon, direction, onClick, children, }) {
    return (_jsxs("button", { onClick: onClick, className: classNames(styles.sortingButton, {
            [styles.hideIcon]: hideIcon,
        }), children: [children, direction === FolderParamDirection.DSC ? (_jsx(FontAwesomeIcon, { icon: faArrowDown, "data-testid": 'sorting-btn-arrow-down' })) : (_jsx(FontAwesomeIcon, { icon: faArrowUp, "data-testid": 'sorting-btn-arrow-up' }))] }));
}
