import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { Player } from 'src/ui/Player';
import { Button } from 'src/ui/cosmosWeb/Button';
import { FontAwesomeIcon } from 'src/application/icons/fontAwesome';
import { faArrowDownToBracket } from 'src/application/icons/fontAwesome/light';
import { faArrowLeft } from 'src/application/icons/fontAwesome/regular';
import { TextTruncate } from 'src/ui/TextTruncate';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { useMediaDownload } from 'src/domain/Media/hooks';
import { Modal } from 'src/ui/cosmosWeb/Modal';
import { Can } from 'src/infrastructure/libs/CASL/can';
import styles from './styles.module.scss';
export const MediaPreviewModal = NiceModal.create(({ media, onDismiss, withDownloadButton = true, }) => {
    const modal = useModal();
    const downloadMedia = useMediaDownload();
    return (_jsxs(Modal, { onCloseModal: () => {
            modal.remove();
            onDismiss?.();
        }, className: styles.modal, opened: modal.visible, dismissible: false, "data-testid": 'media-preview-modal', children: [_jsxs("div", { className: styles.header, slot: 'heading', children: [_jsxs("div", { children: [_jsx(Button, { slot: 'icon', variation: 'tertiary', context: 'neutral', size: 'large', onClick: () => modal.hide(), children: _jsx(FontAwesomeIcon, { icon: faArrowLeft }) }), _jsx(TextTruncate, { children: _jsx(Heading, { strong: true, size: 'micro', children: media.name }) })] }), withDownloadButton && (_jsx(Can, { I: 'download', a: 'Media', children: _jsx(Button, { onClick: () => {
                                downloadMedia(media.code);
                                EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.PREVIEW_DOWNLOAD_MEDIA);
                            }, slot: 'icon', variation: 'tertiary', context: 'neutral', size: 'large', "data-testid": 'download-media-button', children: _jsx(FontAwesomeIcon, { icon: faArrowDownToBracket }) }) }))] }), _jsx("div", { className: styles.player, slot: 'description', children: _jsx(Player, { media: media }) })] }));
});
