import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import { useTranslation } from 'react-i18next';
import importPerson from 'public/assets/svg/import_person.svg';
import { ImportProvider } from '../../ImportMediaProvider/constants';
import styles from './styles.module.scss';
export const SecondStep = ({ provider }) => {
    const { t } = useTranslation();
    return (_jsxs("div", { "data-testid": 'step-container', className: styles.stepContainer, children: [_jsxs("div", { className: styles.instructions, children: [_jsx(Paragraph, { size: 'base', className: styles.subtitle, children: t('library.modal.import_tutorial.step2.subtitle') }), _jsx(Paragraph, { size: 'small', className: styles.content, children: t('library.modal.import_tutorial.step2.content') }), provider === ImportProvider.VIMEO && (_jsx(Paragraph, { "data-testid": 'step-provider-note', size: 'nano', className: styles.note, children: t('library.modal.import_tutorial.step2.note') }))] }), _jsx("div", { className: styles.illustration, children: _jsx("img", { src: importPerson, alt: t('library.modal.import_tutorial.step2.title'), width: 247, height: 227 }) })] }));
};
