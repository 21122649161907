// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PtMd4cajwlsDye9NrymS{--hc-modal-dialog-width: 38.5rem;--hc-modal-dialog-max-width: 38.5rem}.PtMd4cajwlsDye9NrymS .u9gcIgITx7vb1EXFMkXB,.PtMd4cajwlsDye9NrymS .bguQH0MjVYwK6E_jHMQE,.PtMd4cajwlsDye9NrymS ._OSRxvV4VMFknyvf3gsq{width:100%}.PtMd4cajwlsDye9NrymS .w18Bu0GxCSo7GF06tAxH{width:100%;padding:3rem;text-align:center;background-color:var(--hc-color-neutral-100);border-radius:.5rem;border-style:dashed;border-color:var(--hc-color-neutral-300);margin-top:1rem;cursor:pointer}.PtMd4cajwlsDye9NrymS .w18Bu0GxCSo7GF06tAxH input{display:none}.PtMd4cajwlsDye9NrymS .w18Bu0GxCSo7GF06tAxH span{font-size:1rem;line-height:1.5rem;color:var(--hc-color-primary-500);padding:.5rem}`, "",{"version":3,"sources":["webpack://./src/domain/Media/components/UploadAndImport/UploadAndImportModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gCAAA,CACA,oCAAA,CAEA,oIACE,UAAA,CAEF,4CACE,UAAA,CACA,YAAA,CACA,iBAAA,CACA,4CAAA,CACA,mBAAA,CACA,mBAAA,CACA,wCAAA,CACA,eAAA,CACA,cAAA,CACA,kDACE,YAAA,CAEF,iDACE,cAAA,CACA,kBAAA,CACA,iCAAA,CACA,aAAA","sourcesContent":[".modal {\n  --hc-modal-dialog-width: 38.5rem;\n  --hc-modal-dialog-max-width: 38.5rem;\n\n  .modalHeader, .modalBody, .modalFooter {\n    width: 100%;\n  }\n  .uploadFile {\n    width: 100%;\n    padding: 3rem;\n    text-align: center;\n    background-color: var(--hc-color-neutral-100);\n    border-radius: .5rem;\n    border-style: dashed;\n    border-color: var(--hc-color-neutral-300);\n    margin-top: 1rem;\n    cursor: pointer;\n    input {\n      display: none;\n    }\n    span {\n      font-size: 1rem;\n      line-height: 1.5rem;\n      color: var(--hc-color-primary-500);\n      padding: .5rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `PtMd4cajwlsDye9NrymS`,
	"modalHeader": `u9gcIgITx7vb1EXFMkXB`,
	"modalBody": `bguQH0MjVYwK6E_jHMQE`,
	"modalFooter": `_OSRxvV4VMFknyvf3gsq`,
	"uploadFile": `w18Bu0GxCSo7GF06tAxH`
};
export default ___CSS_LOADER_EXPORT___;
