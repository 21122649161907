import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import { Fragment } from 'react/jsx-dev-runtime';
import { ConsumptionMetricsWithPercentage } from 'src/domain/User/Plan/components/ConsumptionMetricsWithPercentage';
import usePlan from 'src/domain/User/Plan/hooks/usePlan';
import ContentLoader from 'src/ui/cosmos/ContentLoader';
import { Alert } from 'src/ui/cosmos/Alert';
import { FontAwesomeIcon } from 'src/application/icons/fontAwesome';
import { faCircleXmark } from 'src/application/icons/fontAwesome/light';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { Toast } from 'src/ui/cosmos/Toast';
import styles from './styles.module.scss';
export const DefaultLayout = () => {
    const { t } = useTranslation();
    const { data: plan, metricsUsage, isLoading } = usePlan();
    const handleContactRequestClick = () => {
        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIMIT_EXCEEDED_WARNING);
        Toast.success({ message: t('plan.toast.successful_contact_request') });
    };
    return (_jsxs(_Fragment, { children: [metricsUsage.map((item) => (_jsxs(Fragment, { children: [isLoading ? (_jsx(ContentLoader, { "data-testid": 'content-loader', className: styles.contentLoader })) : (_jsx(ConsumptionMetricsWithPercentage, { label: t(`plan.info.${item.name}`), total: item.total, used: item.used })), _jsx("span", { className: styles.separator })] }, item.name))), (plan?.storageConsumption >= plan?.storage ||
                plan?.bandwidthConsumption >= plan?.bandwidth) && (_jsxs(_Fragment, { children: [_jsxs(Alert, { className: `hot-alert--danger ${styles.alert}`, children: [_jsx(FontAwesomeIcon, { icon: faCircleXmark, size: 'lg' }), _jsx("span", { children: _jsx(Trans, { i18nKey: 'plan.limit_exceeded_warning', components: {
                                        a: (_jsx("a", { className: 'hot-alert__link', onClick: handleContactRequestClick })),
                                    } }) })] }), _jsx("span", { className: styles.separator })] }))] }));
};
