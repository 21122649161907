// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LSvvYiTYro7doH66uhT7{display:flex;align-items:center;background:none;border:none;padding:0;gap:.25rem;font-size:var(--hc-size-text-medium);cursor:pointer}.LSvvYiTYro7doH66uhT7:focus:not(:focus-visible){outline:none}.LSvvYiTYro7doH66uhT7>svg{width:.75rem;height:.75rem}.LSvvYiTYro7doH66uhT7.mWfBLyMS4qfhANDZrOWc>svg{display:none}.LSvvYiTYro7doH66uhT7:hover.mWfBLyMS4qfhANDZrOWc>svg{display:flex;opacity:.4}`, "",{"version":3,"sources":["webpack://./src/application/pages/Library/components/List/ListHeader/SortingButton/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,eAAA,CACA,WAAA,CACA,SAAA,CACA,UAAA,CACA,oCAAA,CACA,cAAA,CAEA,gDACE,YAAA,CAGF,0BACE,YAAA,CACA,aAAA,CAIA,+CACE,YAAA,CAKF,qDACE,YAAA,CACA,UAAA","sourcesContent":[".sortingButton {\n  display: flex;\n  align-items: center;\n  background: none;\n  border: none;\n  padding: 0;\n  gap: 0.25rem;\n  font-size: var(--hc-size-text-medium);\n  cursor: pointer;\n\n  &:focus:not(:focus-visible) {\n    outline: none;\n  }\n\n  > svg {\n    width: 0.75rem;\n    height: 0.75rem;\n  }\n\n  &.hideIcon {\n    > svg {\n      display: none;\n    }\n  }\n\n  &:hover.hideIcon {\n    > svg {\n      display: flex;\n      opacity: 0.4;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sortingButton": `LSvvYiTYro7doH66uhT7`,
	"hideIcon": `mWfBLyMS4qfhANDZrOWc`
};
export default ___CSS_LOADER_EXPORT___;
