import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { t } from 'i18next';
import { useFolderCount } from 'src/domain/Folder/hooks';
import { useMediaCount } from 'src/domain/Media/hooks';
import ContentLoader from 'src/ui/cosmos/ContentLoader';
import styles from './styles.module.scss';
export const AssetsTotalizer = () => {
    const { data: mediaCount, isLoading: isMediaCountLoading } = useMediaCount();
    const { data: folderCount, isLoading: isFolderCountLoading } = useFolderCount();
    const assetsCountItems = [
        {
            name: t('general.media'),
            total: mediaCount?.total,
            isLoading: isMediaCountLoading,
        },
        {
            name: t('general.folders'),
            total: folderCount?.total,
            isLoading: isFolderCountLoading,
        },
    ];
    return (_jsx("div", { className: styles.row, children: assetsCountItems.map((item) => (_jsxs("div", { children: [_jsx("span", { className: styles.rowTitle, children: item.name }), item.isLoading ? (_jsx(ContentLoader, { "data-testid": 'content-loader', className: styles.contentLoader })) : (_jsx("strong", { children: item.total ?? 0 }))] }, item.name))) }));
};
