import { useQuery } from 'src/application/hooks/useRequest';
import useAccounts from 'src/domain/User/Accounts/hooks/useAccounts';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import FeatureToggleService from 'src/infrastructure/services/FeatureToggleService';
const STALE_TIME = 1000 * 20;
const useFeatureToggle = () => {
    const { currentAccount } = useAccounts();
    const { data: toggles } = useQuery({
        queryKey: [QueryKeys.FEATURE_TOGGLES],
        queryFn: () => FeatureToggleService.getFeatureToggles(currentAccount.accountOwnerMarketplaceId),
        staleTime: STALE_TIME,
    });
    const isEnabled = (featureToggle) => {
        if (!toggles)
            return false;
        const toggle = toggles.find((toggle) => toggle.name === featureToggle);
        if (!toggle)
            return false;
        return toggle.enabled;
    };
    return { toggles, isEnabled };
};
export default useFeatureToggle;
