import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Pagination } from 'src/ui/cosmos/Pagination';
import { HcFormField } from '@hotmart-org-ca/cosmos-web/dist/react/hc-form-field';
import { Select, SelectItem } from 'src/ui/cosmosWeb/Select';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { REGISTERS_PER_PAGE_OPTIONS } from '../constants';
import styles from './styles.module.scss';
export const ListPagination = ({ currentPage, onPageChange, registersPerPage, totalCountOfItems, totalPages, customRegistersPerPage = true, }) => {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const pathname = location.pathname;
    const handleRegisterPerPageChange = (value) => {
        searchParams.set('registerPerPage', value.toString());
        searchParams.delete('page');
        navigate(`${pathname}?${searchParams}`);
    };
    const shouldShowPagination = totalPages > 1 || totalCountOfItems > REGISTERS_PER_PAGE_OPTIONS[0];
    return (_jsx(_Fragment, { children: shouldShowPagination && (_jsxs("div", { className: styles.pagination, "data-testid": 'list-pagination', children: [_jsx(Pagination, { currentPage: currentPage, onPageChange: onPageChange, registersPerPage: registersPerPage, totalCountOfItems: totalCountOfItems }), customRegistersPerPage && (_jsx(HcFormField, { className: styles.formField, children: _jsx(Select, { size: 'medium', className: styles.select, docked: true, "data-testid": 'pagination-select', children: REGISTERS_PER_PAGE_OPTIONS.map((option) => (_jsx(SelectItem, { value: option.toString(), selected: option === registersPerPage, onClick: () => handleRegisterPerPageChange(option), children: `${option} ${t('library.list.pagination.items')}` }, option))) }) }))] })) }));
};
