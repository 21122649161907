import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ContentError } from 'src/ui/ContentError';
import { Breadcrumb } from 'src/application/Layout/Breadcrumb';
import useFolderParents from 'src/domain/Folder/hooks/useFolderParents';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumb as HcBreadcrumb } from 'src/ui/cosmos/Breadcrumb';
import { Loading } from 'src/ui/cosmos/Loading';
import { FolderContentList } from '../Library/containers/FolderContentList';
import Header from './components/Header';
import styles from './styles.module.scss';
const FolderPage = () => {
    const { folderCode } = useParams();
    const { t } = useTranslation('common');
    const { isLoading, data: folders, isError, } = useFolderParents({ code: folderCode });
    if (isLoading) {
        return (_jsx("div", { className: styles.loadingContentWrapper, children: _jsx(Loading, { full: true }) }));
    }
    if (isError)
        return (_jsx(ContentError, { description: t('general.error.folder.description'), title: t('general.error.folder.title') }));
    const currentFolder = folders[folders.length - 1];
    return (_jsxs(_Fragment, { children: [_jsx(HcBreadcrumb.Root, { children: _jsx(Breadcrumb, { folders: folders || [] }) }), _jsxs("div", { children: [_jsx(Header, { folderName: currentFolder.name }), _jsx(FolderContentList, { folderCode: folderCode })] })] }));
};
export default FolderPage;
