// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buz4e7Ozcg2vlddTlGyD{display:flex}.buz4e7Ozcg2vlddTlGyD .p4qPzqBkArA_b0EYpXBf ul{padding-left:1.5rem}.buz4e7Ozcg2vlddTlGyD .p4qPzqBkArA_b0EYpXBf ul li{margin-top:1rem}.buz4e7Ozcg2vlddTlGyD .p4qPzqBkArA_b0EYpXBf .EvOGyRYKeLosbGC_H27X{--hc-p-neutral-color: var(--gray-600)}.buz4e7Ozcg2vlddTlGyD .xj69YxVBaTdCwiozT5n4{padding-left:4.5rem}`, "",{"version":3,"sources":["webpack://./src/domain/Media/components/UploadAndImport/ImportMediaProviderModal/FirstStep/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAGE,+CACE,mBAAA,CACA,kDACE,eAAA,CAGJ,kEACE,qCAAA,CAIJ,4CACE,mBAAA","sourcesContent":[".stepContainer {\n  display: flex;\n\n  .instructions {\n    ul {\n      padding-left: 1.5rem;\n      li {\n        margin-top: 1rem;\n      }\n    }\n    .note {\n      --hc-p-neutral-color: var(--gray-600);\n    }\n  }\n  \n  .illustration {\n    padding-left: 4.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepContainer": `buz4e7Ozcg2vlddTlGyD`,
	"instructions": `p4qPzqBkArA_b0EYpXBf`,
	"note": `EvOGyRYKeLosbGC_H27X`,
	"illustration": `xj69YxVBaTdCwiozT5n4`
};
export default ___CSS_LOADER_EXPORT___;
