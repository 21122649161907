// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tljJpEdAoaZXv7IqZtfF{display:flex}.tljJpEdAoaZXv7IqZtfF .ne9M5fNol0YVwQwIpTG0 .rONYF4gTptzFo9SZZEhz{padding-top:2.125rem;--hc-p-neutral-color: var(--neutral-900)}.tljJpEdAoaZXv7IqZtfF .ne9M5fNol0YVwQwIpTG0 .cgN_LNx1LjuYBHqKUrEf{padding-top:1rem;--hc-p-neutral-color: var(--gray-600)}.tljJpEdAoaZXv7IqZtfF .ne9M5fNol0YVwQwIpTG0 .z9ghCfYjBVaw_WiyTlEP{text-decoration:underline}.tljJpEdAoaZXv7IqZtfF .jc0aRlvg5YqenkVEUL1G{padding-left:4.5rem}`, "",{"version":3,"sources":["webpack://./src/domain/Media/components/UploadAndImport/ImportMediaProviderModal/ThirdStep/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAGE,kEACE,oBAAA,CACA,wCAAA,CAEF,kEACE,gBAAA,CACA,qCAAA,CAEF,kEACE,yBAAA,CAIJ,4CACE,mBAAA","sourcesContent":[".stepContainer {\n  display: flex;\n\n  .instructions {\n    .subtitle {\n      padding-top: 2.125rem;\n      --hc-p-neutral-color: var(--neutral-900);\n    }\n    .content {\n      padding-top: 1rem;\n      --hc-p-neutral-color: var(--gray-600);\n    }\n    .link {\n      text-decoration: underline;\n    }\n  }\n\n  .illustration {\n    padding-left: 4.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepContainer": `tljJpEdAoaZXv7IqZtfF`,
	"instructions": `ne9M5fNol0YVwQwIpTG0`,
	"subtitle": `rONYF4gTptzFo9SZZEhz`,
	"content": `cgN_LNx1LjuYBHqKUrEf`,
	"link": `z9ghCfYjBVaw_WiyTlEP`,
	"illustration": `jc0aRlvg5YqenkVEUL1G`
};
export default ___CSS_LOADER_EXPORT___;
