import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import PlaybackControlCustomizerService from '../../services/PlaybackControlCustomizerService';
const usePlaybackControlCustomizer = ({ mediaCode }) => {
    const [unsavedPreferences, setUnsavedPreferences] = useState({});
    const { data, ...rest } = useQuery({
        queryKey: [QueryKeys.PLAYBACK_CONTROL, mediaCode],
        queryFn: () => PlaybackControlCustomizerService.get({ mediaCode }),
    });
    useEffect(() => {
        if (data) {
            setUnsavedPreferences(data);
        }
    }, [data]);
    const togglePreference = ({ controlName }) => {
        setUnsavedPreferences((oldUnsavedPreferences) => {
            return {
                ...oldUnsavedPreferences,
                [controlName]: !oldUnsavedPreferences[controlName],
            };
        });
    };
    const resetChanges = () => {
        if (data) {
            setUnsavedPreferences(data);
        }
    };
    const hasUnsavedChanges = useMemo(() => {
        return JSON.stringify(data) !== JSON.stringify(unsavedPreferences);
    }, [data, unsavedPreferences]);
    return {
        ...rest,
        data,
        preferences: unsavedPreferences,
        togglePreference,
        hasUnsavedChanges,
        resetChanges,
    };
};
export default usePlaybackControlCustomizer;
