// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.soPNFf5tOTITHHGK2G9T{display:contents}`, "",{"version":3,"sources":["webpack://./src/ui/cosmos/Card/Body/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,gBAAA","sourcesContent":[".cardBody {\n    display: contents;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardBody": `soPNFf5tOTITHHGK2G9T`
};
export default ___CSS_LOADER_EXPORT___;
