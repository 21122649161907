// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.P2ZrWvTPL9sTEzdEpwjI{--hc-dropdown-item-container-padding-block: 0.75rem;--hc-dropdown-item-container-padding-inline: 1rem;--hc-dropdown-menu-min-width: 14.875rem;--hc-dropdown-menu-width: 100%;--hc-dropdown-item-border-bottom: 0;--hc-dropdown-item-hover-background-color: var(--hc-color-neutral-100);--hc-dropdown-menu-max-height: 50vh;--hc-dropdown-item-container-column-gap: var(--hc-size-spacing-3)}.P2ZrWvTPL9sTEzdEpwjI .rCgRGqpnSUh4NnE2KDCN{align-items:center;display:flex;height:1.5rem}.P2ZrWvTPL9sTEzdEpwjI .rCgRGqpnSUh4NnE2KDCN svg{width:1rem;height:1rem;margin-top:-0.25rem}.P2ZrWvTPL9sTEzdEpwjI .d_nRsWl5SPqjficM4Yxj{display:flex;align-items:center}.P2ZrWvTPL9sTEzdEpwjI .x0kQyfYyJ8bg1ouurzJw svg{border-bottom:.0625rem solid}`, "",{"version":3,"sources":["webpack://./src/application/Layout/Breadcrumb/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,mDAAA,CACA,iDAAA,CACA,uCAAA,CACA,8BAAA,CACA,mCAAA,CACA,sEAAA,CACA,mCAAA,CACA,iEAAA,CAEA,4CACE,kBAAA,CACA,YAAA,CACA,aAAA,CAEA,gDACE,UAAA,CACA,WAAA,CACA,mBAAA,CAIJ,4CACE,YAAA,CACA,kBAAA,CAIA,gDACE,4BAAA","sourcesContent":[".dropdown {\n    --hc-dropdown-item-container-padding-block: 0.75rem;\n    --hc-dropdown-item-container-padding-inline: 1rem;\n    --hc-dropdown-menu-min-width: 14.875rem;\n    --hc-dropdown-menu-width: 100%;\n    --hc-dropdown-item-border-bottom: 0;\n    --hc-dropdown-item-hover-background-color: var(--hc-color-neutral-100);\n    --hc-dropdown-menu-max-height: 50vh;\n    --hc-dropdown-item-container-column-gap: var(--hc-size-spacing-3);\n  \n    .icon {\n      align-items: center;\n      display: flex;\n      height: 1.5rem;\n  \n      svg {\n        width: 1rem;\n        height: 1rem;\n        margin-top: -0.25rem;\n      }\n    }\n  \n    .label {\n      display: flex;\n      align-items: center;\n    }\n  \n    .dropdownButton {\n      svg {\n        border-bottom: .0625rem solid;\n      }\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `P2ZrWvTPL9sTEzdEpwjI`,
	"icon": `rCgRGqpnSUh4NnE2KDCN`,
	"label": `d_nRsWl5SPqjficM4Yxj`,
	"dropdownButton": `x0kQyfYyJ8bg1ouurzJw`
};
export default ___CSS_LOADER_EXPORT___;
