// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.j4yNttpbClHGzBmhpok8{padding-top:2.5rem;display:flex;flex-direction:column;gap:1.5rem}.j4yNttpbClHGzBmhpok8 .vdQI50V2Kxeb3FwyZ2HQ{display:grid;grid-template-columns:1fr 24.875rem;gap:1.5rem}.j4yNttpbClHGzBmhpok8 .vdQI50V2Kxeb3FwyZ2HQ>.mWyK4twtG7pQwspu7hqA{display:grid;grid-auto-rows:min-content;gap:1.5rem}`, "",{"version":3,"sources":["webpack://./src/application/pages/Home/containers/DefaultHome/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,UAAA,CAEA,4CACE,YAAA,CACA,mCAAA,CACA,UAAA,CAEA,kEACE,YAAA,CACA,0BAAA,CACA,UAAA","sourcesContent":[".container {\n  padding-top: 2.5rem;\n  display: flex;\n  flex-direction: column;\n  gap: 1.5rem;\n\n  .content {\n    display: grid;\n    grid-template-columns: 1fr 24.875rem;\n    gap: 1.5rem;\n\n    & > .contentColumn {\n      display: grid;\n      grid-auto-rows: min-content;\n      gap: 1.5rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `j4yNttpbClHGzBmhpok8`,
	"content": `vdQI50V2Kxeb3FwyZ2HQ`,
	"contentColumn": `mWyK4twtG7pQwspu7hqA`
};
export default ___CSS_LOADER_EXPORT___;
