export var QueryKeys;
(function (QueryKeys) {
    QueryKeys["LAST_UPDATES_PREVIEW"] = "LAST_UPDATES_PREVIEW";
    QueryKeys["LIBRARY_PREVIEW"] = "LIBRARY_PREVIEW";
    QueryKeys["SUBSCRIPTION_PLAN"] = "SUBSCRIPTION_PLAN";
    QueryKeys["SUBTITLE_SUPPORTED"] = "SUBTITLE_SUPPORTED";
    QueryKeys["SUBTITLE_DOWNLOAD"] = "SUBTITLE_DOWNLOAD";
    QueryKeys["SUBTITLE"] = "SUBTITLE";
    QueryKeys["REPORT_METRICS"] = "REPORT_METRICS";
    QueryKeys["DEFAULT_FOLDER"] = "DEFAULT_FOLDER";
    QueryKeys["MEDIA_URL"] = "MEDIA_URL";
    QueryKeys["FOLDER_MEDIA_LIST"] = "FOLDER_MEDIA_LIST";
    QueryKeys["FOLDER_ITEMS"] = "FOLDER_ITEMS";
    QueryKeys["FOLDER_COUNT"] = "FOLDER_COUNT";
    QueryKeys["FOLDER_LATEST"] = "FOLDER_LATEST";
    QueryKeys["MEDIA_COUNT"] = "MEDIA_COUNT";
    QueryKeys["MEDIA_LATEST"] = "MEDIA_LATEST";
    QueryKeys["MEDIA_DURATION_COUNT"] = "MEDIA_DURATION_COUNT";
    QueryKeys["FOLDER_PARENTS"] = "FOLDER_PARENTS";
    QueryKeys["DOMAINS"] = "DOMAINS";
    QueryKeys["MEDIA_DATA"] = "MEDIA_DATA";
    QueryKeys["SEARCH"] = "SEARCH";
    QueryKeys["LIBRARY"] = "LIBRARY";
    QueryKeys["PLAYBACK_CONTROL"] = "PLAYBACK_CONTROL";
    QueryKeys["MOVE_MODAL_FOLDER"] = "MOVE_MODAL_FOLDER";
    QueryKeys["SUBSCRIPTION_AUTHORIZATION"] = "SUBSCRIPTION_AUTHORIZATION";
    QueryKeys["IMPORT_PROVIDER"] = "IMPORT_PROVIDER";
    QueryKeys["FEATURE_TOGGLES"] = "FEATURE_TOGGLES";
    QueryKeys["MENU_TOOLS"] = "MENU_TOOLS";
    QueryKeys["MEDIA_SHARE"] = "MEDIA_SHARE";
})(QueryKeys || (QueryKeys = {}));
