import { useQuery } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import { useMemo } from 'react';
import PlanService from '../../services/PlanService';
const staleTime = 60 * 1000 * 5; //5 min
const usePlan = () => {
    const { data, ...rest } = useQuery({
        queryKey: [QueryKeys.SUBSCRIPTION_PLAN],
        queryFn: PlanService.getSubscriptionPlan,
        staleTime,
    });
    const metricsUsage = useMemo(() => {
        return [
            {
                name: 'storage',
                used: data?.storageConsumption,
                total: data?.storage,
            },
            {
                name: 'bandwidth',
                used: data?.bandwidthConsumption,
                total: data?.bandwidth,
            },
        ];
    }, [data]);
    return {
        data,
        metricsUsage,
        ...rest,
    };
};
export default usePlan;
