import axios from 'axios';
import { FeatureToggle, } from 'src/application/interfaces/FeatureToggle';
class FeatureToggleService {
    async getFeatureToggles(userId) {
        const { data } = await axios.post(process.env.UNLEASH_PROXY_URL, {
            toggles: Object.values(FeatureToggle),
            context: {
                userId,
            },
        }, {
            headers: {
                Authorization: 'proxy-secret',
            },
        });
        return data.toggles;
    }
}
export default new FeatureToggleService();
