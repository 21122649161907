import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ProfileDrawer } from 'src/ui/ProfileDrawer';
// eslint-disable-next-line max-len
import { useFileUploadProcess } from 'src/domain/Media/modules/MediaUpload/contexts/MediaUploadContext/hooks/useFileUploadProcess';
import UploadDrawer from 'src/domain/Media/modules/MediaUpload/components/Drawer/UploadDrawer';
import { useMediaImports } from 'src/domain/Media/hooks';
import { HeaderAction } from 'src/ui/cosmos/Structure';
import { ToolsMenu } from '../../containers/ToolsMenu';
const HeaderActions = () => {
    const { generalUploadState: isUploading } = useFileUploadProcess();
    const { getAllImports } = useMediaImports();
    const { data: areImportInProgress } = getAllImports;
    return (_jsxs(_Fragment, { children: [(isUploading || areImportInProgress) && (_jsx(HeaderAction, { children: _jsx(UploadDrawer, {}) })), _jsx(HeaderAction, { children: _jsx(ToolsMenu, {}) }), _jsx(HeaderAction, { children: _jsx(ProfileDrawer, {}) })] }));
};
export default HeaderActions;
