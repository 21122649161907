import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import { useTranslation } from 'react-i18next';
import importFileLock from 'public/assets/svg/import_file_lock.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from 'src/application/icons/fontAwesome/regular';
import i18n from 'src/infrastructure/config/i18n';
import styles from './styles.module.scss';
export const ThirdStep = () => {
    const { t } = useTranslation();
    const helpCenterUrl = String(process.env.NEXT_PUBLIC_UPLOAD_HELP_LINK?.replace('{{lang}}', i18n.language));
    return (_jsxs("div", { className: styles.stepContainer, children: [_jsxs("div", { className: styles.instructions, children: [_jsx(Paragraph, { size: 'base', className: styles.subtitle, children: t('library.modal.import_tutorial.step3.subtitle') }), _jsxs(Paragraph, { size: 'small', className: styles.content, children: [_jsx("span", { children: t('library.modal.import_tutorial.step3.content') }), _jsxs("a", { className: styles.link, href: helpCenterUrl, target: '_blank', rel: 'noreferrer', children: [_jsx("span", { children: t('library.modal.import_tutorial.step3.link_text') }), _jsx(FontAwesomeIcon, { icon: faArrowUpRightFromSquare })] })] })] }), _jsx("div", { className: styles.illustration, children: _jsx("img", { src: importFileLock, alt: t('library.modal.import_tutorial.step3.title'), width: 227, height: 227 }) })] }));
};
