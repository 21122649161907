import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from 'src/application/icons/fontAwesome';
import { faSpinnerThird } from 'src/application/icons/fontAwesome/regular';
import { TextTruncate } from 'src/ui/TextTruncate';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
const Discovered = () => {
    const { t } = useTranslation();
    return (_jsxs("div", { className: styles.discovered, "data-testid": 'import-component-discovered', children: [_jsx("div", { className: styles.name, children: _jsx(TextTruncate, { children: _jsx("div", { className: styles.title, children: t('upload.import.loading_files') }) }) }), _jsx("div", { className: styles.icon, children: _jsx(FontAwesomeIcon, { icon: faSpinnerThird, spin: true, size: 'lg' }) })] }));
};
export default Discovered;
