import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-len */
import { Loading } from 'src/ui/cosmos/Loading';
import { ContentFeedback } from 'src/ui/ContentFeedback';
import { List } from 'src/application/pages/Library/components/List';
import { LIBRARY_CONTENT_FILTER } from 'src/application/pages/Library/Interfaces/enum';
import useContentLibrary from 'src/application/pages/Library/hook/useContentLibrary';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import contentErrorImg from 'public/assets/svg/content_error.svg';
import emptyFolderImg from 'public/assets/svg/empty_folder.svg';
import { Can } from 'src/infrastructure/libs/CASL';
import { useRef, useState } from 'react';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { INITIAL_FILE_CONFIG_ALLOWED } from 'src/domain/Media/modules/MediaUpload/contexts/MediaUploadContext/constants';
import { GeneralStatus } from 'src/domain/Media/modules/MediaUpload/interfaces/FileUploadProps';
import { useFileUploadProcess } from 'src/domain/Media/modules/MediaUpload/contexts/MediaUploadContext/hooks/useFileUploadProcess';
import { Button } from 'src/ui/cosmosWeb/Button';
import { LibraryAlert } from '../../../components/LibraryAlert';
import { useSort } from '../../../components/List/hooks/useSort';
import { REGISTERS_PER_PAGE_OPTIONS } from '../../../components/List/constants';
import styles from './styles.module.scss';
export const LibraryContentList = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const inputFileRef = useRef(null);
    const [files, setFiles] = useState('');
    const { onSelectFiles, generalUploadState } = useFileUploadProcess();
    const hasPendingFile = generalUploadState === GeneralStatus.UPLOADING;
    const { t } = useTranslation();
    const filter = searchParams.get('filter');
    const page = Number(searchParams.get('page'));
    const registerPerPage = Number(searchParams.get('registerPerPage'));
    const isAValidPage = page && !isNaN(page);
    const isAvalidFilter = filter && LIBRARY_CONTENT_FILTER[filter];
    const isAvalidRegisterPerPage = registerPerPage && REGISTERS_PER_PAGE_OPTIONS.includes(registerPerPage);
    const currentPage = isAValidPage ? page - 1 : 0;
    const currentFilter = isAvalidFilter
        ? filter
        : LIBRARY_CONTENT_FILTER.all_files;
    const currentRegisterPerPage = isAvalidRegisterPerPage
        ? registerPerPage
        : REGISTERS_PER_PAGE_OPTIONS[0];
    const { key, direction, handleSorting } = useSort();
    const { isLoading, isError, isPreviousData, data } = useContentLibrary({
        elementPerPage: currentRegisterPerPage,
        filter: currentFilter,
        page: currentPage,
        key,
        direction,
    });
    const handleChangePage = (page) => {
        searchParams.set('page', page.toString());
        navigate(`/library?${searchParams}`);
    };
    if (isLoading || isPreviousData) {
        return (_jsx("div", { className: styles.loadingContentWrapper, children: _jsx(Loading, { full: true }) }));
    }
    if (isError) {
        return (_jsx("div", { className: styles.contentFeedbackWrapper, children: _jsx(ContentFeedback, { title: t('general.load_content_error.title'), description: t('general.load_content_error.description'), svg: contentErrorImg }) }));
    }
    if (data.content.length === 0)
        return (_jsx("div", { className: styles.contentFeedbackWrapper, children: _jsxs(ContentFeedback, { title: t('library.list.empty.title'), description: t('library.list.empty.description'), svg: emptyFolderImg, children: [_jsx(Button, { variation: 'primary', size: 'medium', onClick: () => {
                            inputFileRef.current?.click();
                            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_NEW_UPLOAD);
                        }, children: t('library.list.empty.button') }), _jsx(Can, { I: 'upload', a: 'Media', children: _jsx("input", { ref: inputFileRef, "data-testid": 'empty-media-page-input-file', accept: INITIAL_FILE_CONFIG_ALLOWED.supportedFiles.join(','), type: 'file', multiple: true, disabled: hasPendingFile, value: files, onChange: (e) => {
                                onSelectFiles(e.target.files, '');
                                setFiles('');
                            } }) })] }) }));
    return (_jsx(List.Root, { data: data.content, children: (table) => {
            return (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.alert, children: _jsx(LibraryAlert, {}) }), _jsx(List.Header, { selectedItems: table
                            .getSelectedRowModel()
                            .flatRows.map((item) => item.original), areAllSelected: table.getIsAllPageRowsSelected(), handleToggleSelectAll: table.toggleAllPageRowsSelected, clearSelection: table.resetRowSelection, sorting: {
                            key,
                            direction,
                            onSort: handleSorting,
                        } }), _jsx(List.Body, { children: table.getRowModel().rows.map((row) => (_jsx(List.Row, { item: row.original, isSelected: row.getIsSelected(), toggleSelect: row.toggleSelected }, row.original.code))) }), _jsx(List.Pagination, { totalPages: data.totalPages, currentPage: currentPage + 1, onPageChange: handleChangePage, registersPerPage: currentRegisterPerPage, totalCountOfItems: data.totalElements })] }));
        } }));
};
