import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { FolderThumb } from 'src/ui/FolderThumb';
import { TextTruncate } from 'src/ui/TextTruncate';
import classNames from 'classnames';
import styles from './styles.module.scss';
const FolderItem = ({ folder, className, ...rest }) => {
    return (_jsx("div", { className: classNames(styles.container, className), ...rest, children: _jsxs(Link, { to: `/library/folder/${folder.code}`, children: [_jsx(FolderThumb, { size: 'small', className: styles.thumb }), _jsx("strong", { children: _jsx(TextTruncate, { children: folder.name }) })] }) }));
};
export default FolderItem;
