/* eslint-disable max-lines */
import myPanelSVG from 'public/assets/svg/toolsMenu/panel.svg';
import myProductsSVG from 'public/assets/svg/toolsMenu/products.svg';
import mySalesSVG from 'public/assets/svg/toolsMenu/sales.svg';
import metricsSVG from 'public/assets/svg/toolsMenu/metrics.svg';
import balanceSVG from 'public/assets/svg/toolsMenu/balance.svg';
import clubSVG from 'public/assets/svg/toolsMenu/club.svg';
import membersAreaSVG from 'public/assets/svg/toolsMenu/members-area.svg';
import paymentsPageSVG from 'public/assets/svg/toolsMenu/payments-page.svg';
import affiliatesProgramSVG from 'public/assets/svg/toolsMenu/affiliates-program.svg';
import affiliationMarketSVG from 'public/assets/svg/toolsMenu/affiliation-market.svg';
import customerSegmentationSVG from 'public/assets/svg/toolsMenu/customer-segmentation.svg';
import klickpagesSVG from 'public/assets/svg/toolsMenu/klickpages.svg';
import klicksendSVG from 'public/assets/svg/toolsMenu/klicksend.svg';
import webinarSVG from 'public/assets/svg/toolsMenu/webinar.svg';
import linkManagerSVG from 'public/assets/svg/toolsMenu/linkManager.svg';
import affiliateToolsSVG from 'public/assets/svg/toolsMenu/affiliate-tools.svg';
import trackingPixelSVG from 'public/assets/svg/toolsMenu/pixel-de-rastreamento.svg';
import reportsSVG from 'public/assets/svg/toolsMenu/menu-reports.svg';
import audienceSVG from 'public/assets/svg/toolsMenu/audience.svg';
import contentLibrarySVG from 'public/assets/svg/toolsMenu/content-library.svg';
import { getURLWithTokenFromKlickPage } from 'src/infrastructure/endpoints/menuTools';
import { KLICKPAGE_TOOL } from 'src/infrastructure/endpoints/menuTools/interfaces';
import { MenuToolsIntentEvent } from './interfaces';
const openKlickTool = (page) => {
    getURLWithTokenFromKlickPage(page).then((response) => window.open(response, '_blank', 'noopener'));
};
const menuItems = {
    content_library: {
        title: 'menu_tools.tools.content_library',
        route: '/',
        icon: contentLibrarySVG,
        dataHubEvent: MenuToolsIntentEvent.MENU_TOOLS_CONTENT_LIBRARY,
    },
    audience: {
        title: 'menu_tools.tools.audience',
        route: `${process.env.EXTERNAL_URL_MENU_TOOLS_BUSINESS_WORKSPACE}/tools/list/producer/talk_audience`,
        icon: audienceSVG,
        dataHubEvent: MenuToolsIntentEvent.MENU_TOOLS_SIDEMENU_AUDIENCE,
    },
    my_panel: {
        title: 'menu_tools.tools.my_panel',
        route: `${process.env.EXTERNAL_URL_MENU_TOOLS_BUSINESS_WORKSPACE}/dashboard`,
        icon: myPanelSVG,
        testid: 'my_panel',
        dataHubEvent: MenuToolsIntentEvent.MENU_TOOLS_MY_PANEL,
    },
    my_products: {
        title: 'menu_tools.tools.my_products',
        route: `${process.env.EXTERNAL_URL_MENU_TOOLS_BUSINESS_WORKSPACE}/products`,
        icon: myProductsSVG,
        testid: 'my_products',
        dataHubEvent: MenuToolsIntentEvent.MENU_TOOLS_MY_PRODUCTS,
    },
    my_sales: {
        title: 'menu_tools.tools.my_sales',
        route: `${process.env.EXTERNAL_URL_MENU_TOOLS_BUSINESS_WORKSPACE}/sales`,
        icon: mySalesSVG,
        testid: 'my_sales',
        dataHubEvent: MenuToolsIntentEvent.MENU_TOOLS_MY_SALES,
    },
    metrics: {
        title: 'menu_tools.tools.my_analysis',
        route: `${process.env.EXTERNAL_URL_MENU_TOOLS_BUSINESS_WORKSPACE}/metrics`,
        icon: metricsSVG,
        testid: 'metrics',
        dataHubEvent: MenuToolsIntentEvent.MENU_TOOLS_MY_ANALYSIS,
    },
    member_area: {
        title: 'menu_tools.tools.course_management',
        route: `${process.env.EXTERNAL_URL_MENU_TOOLS_BUSINESS_WORKSPACE}/members-area`,
        icon: membersAreaSVG,
        testid: 'member_area',
        dataHubEvent: MenuToolsIntentEvent.MENU_TOOLS_COURSE_MANAGEMENT,
    },
    balance: {
        title: 'menu_tools.tools.balance',
        route: `${process.env.EXTERNAL_URL_MENU_TOOLS_BUSINESS_WORKSPACE}/wallet`,
        icon: balanceSVG,
        testid: 'balance',
        dataHubEvent: MenuToolsIntentEvent.MENU_TOOLS_BALANCE,
    },
    club_membership: {
        title: 'menu_tools.tools.club_membership',
        route: `${process.env.EXTERNAL_URL_MENU_TOOLS_BUSINESS_WORKSPACE}/membership/list`,
        icon: clubSVG,
        testid: 'club_membership',
        dataHubEvent: MenuToolsIntentEvent.MENU_TOOLS_CLUB_MEMBERSHIP,
    },
    payment_page_config: {
        title: 'menu_tools.tools.payments_page',
        route: `${process.env.EXTERNAL_URL_MENU_TOOLS_BUSINESS_WORKSPACE}/products/manage/_/tools/checkout/settings`,
        icon: paymentsPageSVG,
        testid: 'payment_page_config',
        dataHubEvent: MenuToolsIntentEvent.MENU_TOOLS_PAYMENTS_PAGE,
    },
    affiliate_program: {
        title: 'menu_tools.tools.affiliate_program',
        route: `${process.env.EXTERNAL_URL_MENU_TOOLS_BUSINESS_WORKSPACE}/products/manage/_/affiliates-program`,
        icon: affiliatesProgramSVG,
        testid: 'affiliate_program',
        dataHubEvent: MenuToolsIntentEvent.MENU_TOOLS_AFFILIATE_PROGRAM,
    },
    affiliation_market: {
        title: 'menu_tools.tools.affiliation_market',
        route: `${process.env.EXTERNAL_URL_MENU_TOOLS_BUSINESS_WORKSPACE}/market`,
        icon: affiliationMarketSVG,
        testid: 'affiliation_market',
        dataHubEvent: MenuToolsIntentEvent.MENU_TOOLS_AFFILIATION_MARKET,
    },
    affiliate_tools: {
        title: 'menu_tools.tools.affiliate_tools',
        route: `${process.env.EXTERNAL_URL_MENU_TOOLS_BUSINESS_WORKSPACE}/tools/list/affiliate`,
        icon: affiliateToolsSVG,
        testid: 'affiliate_tools',
        dataHubEvent: MenuToolsIntentEvent.MENU_TOOLS_AFFILIATE,
    },
    tools: {
        title: 'menu_tools.tools.affiliate_tools',
        route: `${process.env.EXTERNAL_URL_MENU_TOOLS_BUSINESS_WORKSPACE}/tools`,
        icon: affiliateToolsSVG,
        testid: 'tools',
        dataHubEvent: MenuToolsIntentEvent.MENU_TOOLS_AFFILIATE_TOOLS,
    },
    listboss: {
        title: 'menu_tools.tools.customer_segmentation',
        route: `${process.env.EXTERNAL_URL_MENU_TOOLS_BUSINESS_WORKSPACE}/tools/manage/_/listboss`,
        icon: customerSegmentationSVG,
        testid: 'listboss',
        dataHubEvent: MenuToolsIntentEvent.MENU_TOOLS_CUSTOMER_SEGMENTATION,
    },
    link_manager: {
        title: 'menu_tools.tools.link_manager',
        route: `${process.env.EXTERNAL_URL_MENU_TOOLS_BUSINESS_WORKSPACE}/shortener`,
        icon: linkManagerSVG,
        testid: 'link_manager',
        dataHubEvent: MenuToolsIntentEvent.MENU_TOOLS_LINK_MANAGER,
    },
    tracking_pixel: {
        title: 'menu_tools.tools.tracking_pixel',
        route: `${process.env.EXTERNAL_URL_MENU_TOOLS_BUSINESS_WORKSPACE}/tools/pixel`,
        icon: trackingPixelSVG,
        testid: 'tracking_pixel',
        dataHubEvent: MenuToolsIntentEvent.MENU_TOOLS_TRACKING_PIXEL,
    },
    klickpages: {
        title: 'menu_tools.tools.klickpages',
        route: () => openKlickTool(KLICKPAGE_TOOL.KLICK_PAGES),
        icon: klickpagesSVG,
        testid: 'klickpages',
        dataHubEvent: MenuToolsIntentEvent.MENU_TOOLS_KLICKPAGES,
    },
    klicksend: {
        title: 'menu_tools.tools.klicksend',
        route: () => openKlickTool(KLICKPAGE_TOOL.KLICK_SEND),
        icon: klicksendSVG,
        testid: 'klicksend',
        dataHubEvent: MenuToolsIntentEvent.MENU_TOOLS_KLICKSEND,
    },
    webinar: {
        title: 'menu_tools.tools.webinar',
        route: `${process.env.EXTERNAL_URL_MENU_TOOLS_BUSINESS_WORKSPACE_WEBINAR}`,
        icon: webinarSVG,
        testid: 'webinar',
        dataHubEvent: MenuToolsIntentEvent.MENU_TOOLS_WEBINAR,
    },
    reports: {
        title: 'menu_tools.tools.reports',
        route: `${process.env.EXTERNAL_URL_MENU_TOOLS_BUSINESS_WORKSPACE}/reports`,
        icon: reportsSVG,
        testid: 'reports',
        dataHubEvent: MenuToolsIntentEvent.MENU_TOOLS_REPORTS,
    },
};
export { menuItems };
