import Api from 'src/infrastructure/services/Api';
const validateImportKey = async ({ provider, token }) => {
    const { data } = await Api.call({
        method: 'POST',
        url: `${process.env.API_GATEWAY}/media-import/validate`,
        data: {
            serviceToken: token,
            service: provider,
        },
    });
    return data;
};
const getLatestImport = async () => {
    const { data } = await Api.call({
        method: 'GET',
        url: `${process.env.API_GATEWAY}/media-import`,
    });
    return data;
};
const startImport = async ({ provider, token }) => {
    const { data } = await Api.call({
        method: 'POST',
        url: `${process.env.API_GATEWAY}/media-import/create`,
        data: {
            service: provider,
            serviceToken: token,
        },
    });
    return data;
};
const retryFailedImport = async ({ item }) => {
    const { data } = await Api.call({
        method: 'POST',
        url: `${process.env.API_GATEWAY}/media-import/reprocess/${item.id}?limit=${item.failed}&failed=true`,
    });
    return data;
};
export { getLatestImport, retryFailedImport, validateImportKey, startImport };
