import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon, faFolder, faEllipsis, } from 'src/application/icons/fontAwesome/light';
import { TextTruncate } from 'src/ui/TextTruncate';
import { Button } from 'src/ui/cosmosWeb/Button';
import { Dropdown, DropdownItem } from 'src/ui/cosmosWeb/DropDown';
import { Tooltip } from 'src/ui/cosmos/Tooltip';
import { Breadcrumb as HcBreadcrumb } from 'src/ui/cosmos/Breadcrumb';
import { Link, useNavigate } from 'react-router-dom';
import { Fragment } from 'react/jsx-dev-runtime';
import styles from './styles.module.scss';
const MAX_NUMBER_OF_CHARACTERS = 24;
const ITEMS_KEEP_OUT_DROPDOWN = 5;
const MIN_ITEMS_TO_SHOW_DROPDOWN = 3;
function isALargeName(name) {
    return name.length > MAX_NUMBER_OF_CHARACTERS;
}
function getBreadcrumbLink(folder) {
    if (folder.defaultFolder) {
        return '/library';
    }
    return `/library/folder/${folder.code}`;
}
const getBreadcrumbContentWithLink = (folder) => {
    return (_jsx(Link, { to: getBreadcrumbLink(folder), children: _jsx(Tooltip, { text: isALargeName(folder.name) ? folder.name : '', position: 'bottom', children: _jsx(TextTruncate, { children: folder.name }) }) }));
};
const getBreadcrumbContentWithOutLink = (name) => {
    return (_jsx(Tooltip, { text: isALargeName(name) ? name : '', position: 'bottom', children: _jsx(TextTruncate, { children: name }) }));
};
export const Breadcrumb = ({ folders, media }) => {
    const navigate = useNavigate();
    const hasSomeMedia = media && Object.values(media).length > 0;
    const Wrap = hasSomeMedia ? HcBreadcrumb.Root : Fragment;
    if (folders.length === 0) {
        return null;
    }
    const [firstFolder, ...restFolders] = folders;
    const areThereEnoughItemsToShowDropDown = restFolders.length - ITEMS_KEEP_OUT_DROPDOWN >
        MIN_ITEMS_TO_SHOW_DROPDOWN - 1;
    const foldersIntoDropdown = areThereEnoughItemsToShowDropDown &&
        restFolders.slice(0, restFolders.length - ITEMS_KEEP_OUT_DROPDOWN);
    const foldersOutDropdown = foldersIntoDropdown.length > 0
        ? restFolders.slice(foldersIntoDropdown.length, restFolders.length)
        : restFolders;
    return (_jsxs(Wrap, { children: [_jsx(HcBreadcrumb.Item, { children: getBreadcrumbContentWithLink(firstFolder) }), foldersIntoDropdown && (_jsx(HcBreadcrumb.Item, { children: _jsxs(Dropdown, { className: styles.dropdown, slot: 'actions', position: 'start', children: [_jsx(Button, { "data-testid": 'folder-breadcrumb-dropdown', slot: 'controls', className: styles.dropdownButton, variation: 'tertiary', context: 'neutral', size: 'small', children: _jsx("div", { slot: 'icon', children: _jsx(FontAwesomeIcon, { icon: faEllipsis }) }) }), foldersIntoDropdown.map((folder) => (_jsxs(DropdownItem, { slot: 'items', "data-testid": 'folder-breadcrumb-dropdown-name', onClick: () => navigate(getBreadcrumbLink(folder)), children: [_jsx("div", { className: styles.icon, slot: 'icon', children: _jsx(FontAwesomeIcon, { icon: faFolder }) }), _jsx("div", { className: styles.label, slot: 'label', children: getBreadcrumbContentWithOutLink(folder.name) })] }, folder.code)))] }) })), !hasSomeMedia ? (_jsx(_Fragment, { children: foldersOutDropdown?.map((folder, index) => {
                    const lastFolderOutDropdown = foldersOutDropdown.length - 1;
                    return (_jsx(HcBreadcrumb.Item, { active: index === lastFolderOutDropdown, children: index === lastFolderOutDropdown
                            ? getBreadcrumbContentWithOutLink(folder.name)
                            : getBreadcrumbContentWithLink(folder) }, folder.code));
                }) })) : (_jsxs(_Fragment, { children: [foldersOutDropdown?.map((folder) => (_jsx(HcBreadcrumb.Item, { children: getBreadcrumbContentWithLink(folder) }, folder.code))), _jsx(HcBreadcrumb.Item, { active: true, children: getBreadcrumbContentWithOutLink(media.name) })] }))] }));
};
