import { getMediasShare, addMediaShare, deleteMediaShare, } from 'src/infrastructure/endpoints/share';
class MediaShareService {
    getMediasShare() {
        return getMediasShare();
    }
    addMediaShare(mediaCode) {
        return addMediaShare(mediaCode);
    }
    deleteMediaShare(mediaCode) {
        return deleteMediaShare(mediaCode);
    }
}
export default new MediaShareService();
