// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.veDhHxLUt2FeYA0aqzWr{--hc-h-base-lg-font-size: var(--hc-size-text-2extralarge);--hc-h-base-md-font-size: var(--hc-size-text-2extralarge);padding:1rem;border-radius:.5rem;background-color:var(--white);--hc-h-margin: 0}.veDhHxLUt2FeYA0aqzWr p{color:var(--hc-color-neutral-500);margin:0}`, "",{"version":3,"sources":["webpack://./src/application/pages/Home/components/WelcomeCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,yDAAA,CACA,yDAAA,CACA,YAAA,CACA,mBAAA,CACA,6BAAA,CACA,gBAAA,CAEA,wBACE,iCAAA,CACA,QAAA","sourcesContent":[".welcomeCard {\n    --hc-h-base-lg-font-size: var(--hc-size-text-2extralarge);\n    --hc-h-base-md-font-size: var(--hc-size-text-2extralarge);\n    padding: 1rem;\n    border-radius: 0.5rem;\n    background-color: var(--white);\n    --hc-h-margin: 0;\n    \n    p {\n      color: var(--hc-color-neutral-500);\n      margin: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"welcomeCard": `veDhHxLUt2FeYA0aqzWr`
};
export default ___CSS_LOADER_EXPORT___;
