import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Input from 'src/ui/Input';
import TextArea from 'src/ui/TextArea';
import { Button } from 'src/ui/cosmosWeb/Button';
import { Tooltip } from 'src/ui/cosmos/Tooltip';
import { faCircleQuestion } from 'src/application/icons/fontAwesome/regular';
import { FontAwesomeIcon } from 'src/application/icons/fontAwesome';
import styles from './styles.module.scss';
const MediaShareCopyToClipboard = ({ link, onCopyLink, embedCode, onCopyEmbedCode, }) => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.contentField, children: [_jsxs("div", { children: [_jsx("label", { children: t('share.link') }), _jsx(Input, { defaultValue: link, disabled: true, "data-testid": 'input-media-link' })] }), _jsx(Button, { variation: 'secondary', "data-testid": 'copy-link-btn', onClick: onCopyLink, className: styles.copyButton, children: t('share.copy_link') })] }), _jsxs("div", { className: styles.contentField, children: [_jsxs("div", { children: [_jsx("label", { children: _jsxs(Tooltip, { text: t `share.copy_code_more_info`, children: [t('share.embed_code'), _jsx("sup", { children: _jsx(FontAwesomeIcon, { icon: faCircleQuestion }) })] }) }), _jsx(TextArea, { defaultValue: embedCode, rows: 3, disabled: true, "data-testid": 'textarea-media-embed' })] }), _jsx(Button, { variation: 'secondary', onClick: onCopyEmbedCode, "data-testid": 'copy-embed-btn', className: styles.copyButton, children: t('share.copy_code') })] })] }));
};
export default MediaShareCopyToClipboard;
