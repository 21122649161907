// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FBwbnm3AZ432Z6piuqmP{display:flex}.FBwbnm3AZ432Z6piuqmP .larBAVqqah1ngBr1NODV .dJSyGmgU1UBFOFiv7Kbf{padding-top:2.125rem;--hc-p-neutral-color: var(--neutral-900)}.FBwbnm3AZ432Z6piuqmP .larBAVqqah1ngBr1NODV .zzI5eUKQ3da9kWFx398O,.FBwbnm3AZ432Z6piuqmP .larBAVqqah1ngBr1NODV .hqynBT_sWllRAJQ1wPNr{padding-top:1rem;--hc-p-neutral-color: var(--gray-600)}.FBwbnm3AZ432Z6piuqmP .DA0iauUJqw1vgkoYGdkw{padding-left:4.5rem}`, "",{"version":3,"sources":["webpack://./src/domain/Media/components/UploadAndImport/ImportMediaProviderModal/SecondStep/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAGE,kEACE,oBAAA,CACA,wCAAA,CAEF,oIAEE,gBAAA,CACA,qCAAA,CAIJ,4CACE,mBAAA","sourcesContent":[".stepContainer {\n  display: flex;\n\n  .instructions {\n    .subtitle {\n      padding-top: 2.125rem;\n      --hc-p-neutral-color: var(--neutral-900);\n    }\n    .note,\n    .content {\n      padding-top: 1rem;\n      --hc-p-neutral-color: var(--gray-600);\n    }\n  }\n\n  .illustration {\n    padding-left: 4.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepContainer": `FBwbnm3AZ432Z6piuqmP`,
	"instructions": `larBAVqqah1ngBr1NODV`,
	"subtitle": `dJSyGmgU1UBFOFiv7Kbf`,
	"note": `zzI5eUKQ3da9kWFx398O`,
	"content": `hqynBT_sWllRAJQ1wPNr`,
	"illustration": `DA0iauUJqw1vgkoYGdkw`
};
export default ___CSS_LOADER_EXPORT___;
