// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cLQDZjv12Ww6g8urVxSb{width:33.25rem}.cLQDZjv12Ww6g8urVxSb .lTsa5me04OhEblTkqz3z{display:block;margin:auto}.cLQDZjv12Ww6g8urVxSb .ZYArsG7BHxo7Tvdw8_kr{margin:1rem 6rem;text-align:center;--hc-p-neutral-color: var(--gray-800)}.cLQDZjv12Ww6g8urVxSb .MmdmWLT0NUJy91_SKGmn{text-align:center;--hc-p-neutral-color: var(--gray-400)}.cLQDZjv12Ww6g8urVxSb .rTz6kuIcGp_V7RA7JPPE{margin:1rem auto .75rem;width:fit-content}.cLQDZjv12Ww6g8urVxSb .rTz6kuIcGp_V7RA7JPPE .NkRR9XyzJrhI8gybNoMF{--hc-button-width: 11.75rem;--hc-button-background-color: var(--blue)}`, "",{"version":3,"sources":["webpack://./src/domain/Media/components/UploadAndImport/ImportSuccessModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CAEA,4CACE,aAAA,CACA,WAAA,CAEF,4CACE,gBAAA,CACA,iBAAA,CACA,qCAAA,CAEF,4CACE,iBAAA,CACA,qCAAA,CAEF,4CACE,uBAAA,CACA,iBAAA,CAEA,kEACE,2BAAA,CACA,yCAAA","sourcesContent":[".modal {\n  width: 33.25rem;\n\n  .illustration {\n    display: block;\n    margin: auto;\n  }\n  .title {\n    margin: 1rem 6rem;\n    text-align: center;\n    --hc-p-neutral-color: var(--gray-800);\n  }\n  .content {\n    text-align: center;\n    --hc-p-neutral-color: var(--gray-400);\n  }\n  .buttonContainer {\n    margin: 1rem auto 0.75rem;\n    width: fit-content;\n\n    .button {\n      --hc-button-width: 11.75rem;\n      --hc-button-background-color: var(--blue);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `cLQDZjv12Ww6g8urVxSb`,
	"illustration": `lTsa5me04OhEblTkqz3z`,
	"title": `ZYArsG7BHxo7Tvdw8_kr`,
	"content": `MmdmWLT0NUJy91_SKGmn`,
	"buttonContainer": `rTz6kuIcGp_V7RA7JPPE`,
	"button": `NkRR9XyzJrhI8gybNoMF`
};
export default ___CSS_LOADER_EXPORT___;
