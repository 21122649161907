// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._Hlwr1kEgCelIsvWTAXw{border:.0625rem solid var(--hc-color-neutral-200);border-radius:.5rem;height:4.375rem;padding:1.5rem}._Hlwr1kEgCelIsvWTAXw .ZUoEkzs6GnhsrMpc9jMO{align-items:center;display:table-cell;width:100%}._Hlwr1kEgCelIsvWTAXw .KuKxrr3msTKouH0fkHhg{display:table-cell;--hc-button-background-color: transparent;--hc-button-color: var(--hc-color-neutral-700);--hc-button-border-color: transparent;--hc-button-hover-background-color: transparent;--hc-button-hover-color: var(--hc-color-neutral-700);--hc-button-hover-outline: 0;--hc-button-hover-border-color: transparent;--hc-button-padding: var(--hc-size-spacing-1)}._Hlwr1kEgCelIsvWTAXw .KuKxrr3msTKouH0fkHhg>svg{color:var(--hc-color-success-400);fill:var(--hc-color-success-400)}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/MediaUpload/components/ImportMediaProvider/success/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iDAAA,CACA,mBAAA,CACA,eAAA,CACA,cAAA,CAEA,4CACE,kBAAA,CACA,kBAAA,CACA,UAAA,CAGF,4CACE,kBAAA,CACA,yCAAA,CACA,8CAAA,CACA,qCAAA,CACA,+CAAA,CACA,oDAAA,CACA,4BAAA,CACA,2CAAA,CACA,6CAAA,CAEA,gDACE,iCAAA,CACA,gCAAA","sourcesContent":[".success {\n  border: 0.0625rem solid var(--hc-color-neutral-200);\n  border-radius: 0.5rem;\n  height: 4.375rem;\n  padding: 1.5rem;\n\n  .name {\n    align-items: center;\n    display: table-cell;\n    width: 100%;\n  }\n\n  .icon {\n    display: table-cell;\n    --hc-button-background-color: transparent;\n    --hc-button-color: var(--hc-color-neutral-700);\n    --hc-button-border-color: transparent;\n    --hc-button-hover-background-color: transparent;\n    --hc-button-hover-color: var(--hc-color-neutral-700);\n    --hc-button-hover-outline: 0;\n    --hc-button-hover-border-color: transparent;\n    --hc-button-padding: var(--hc-size-spacing-1);\n\n    > svg {\n      color: var(--hc-color-success-400);\n      fill: var(--hc-color-success-400);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"success": `_Hlwr1kEgCelIsvWTAXw`,
	"name": `ZUoEkzs6GnhsrMpc9jMO`,
	"icon": `KuKxrr3msTKouH0fkHhg`
};
export default ___CSS_LOADER_EXPORT___;
