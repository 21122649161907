import Api from 'src/infrastructure/services/Api';
const getMenuToolsItems = async () => {
    const response = await Api.call({
        method: 'GET',
        url: `${process.env.MENU_TOOLS_BUSINESS_WORKSPACE_API}/v1/menu/main`,
    });
    return response.data;
};
const getURLWithTokenFromKlickPage = async (page) => {
    const { data } = await Api.call({
        method: 'GET',
        url: `${process.env.MENU_TOOLS_BUSINESS_WORKSPACE_API_KLICK_PAGE_PRO}/api/v1/login/${page}`,
    });
    return data;
};
export { getMenuToolsItems, getURLWithTokenFromKlickPage };
