// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c87yIrjrq7b66nQ2viK9{display:flex;width:100%;align-items:center;justify-content:center;margin-top:2rem;gap:1rem}.c87yIrjrq7b66nQ2viK9 .zOiu7la2u0VoOdIvBAfQ{--hc-form-field-margin-bottom: -0.5rem}.c87yIrjrq7b66nQ2viK9 .jy8R9lvTp3AsTsox5Xon{--hc-select-select-input-width: 4.6875rem;--hc-select-clear-icon-display: none;--hc-select-item-border-bottom: 0;--hc-select-select-input-padding: var(--hc-size-spacing-2) 0 var(--hc-size-spacing-2) var(--hc-size-spacing-4);--hc-select-item-font-size: var(--hc-size-text-medium)}`, "",{"version":3,"sources":["webpack://./src/application/pages/Library/components/List/ListPagination/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CACA,QAAA,CAEA,4CACE,sCAAA,CAGF,4CACE,yCAAA,CACA,oCAAA,CACA,iCAAA,CACA,8GAAA,CAEA,sDAAA","sourcesContent":[".pagination {\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  margin-top: 2rem;\n  gap: 1rem;\n\n  .formField {\n    --hc-form-field-margin-bottom: -0.5rem;\n  }\n\n  .select {\n    --hc-select-select-input-width: 4.6875rem;\n    --hc-select-clear-icon-display: none;\n    --hc-select-item-border-bottom: 0;\n    --hc-select-select-input-padding: var(--hc-size-spacing-2) 0\n      var(--hc-size-spacing-2) var(--hc-size-spacing-4);\n    --hc-select-item-font-size: var(--hc-size-text-medium);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `c87yIrjrq7b66nQ2viK9`,
	"formField": `zOiu7la2u0VoOdIvBAfQ`,
	"select": `jy8R9lvTp3AsTsox5Xon`
};
export default ___CSS_LOADER_EXPORT___;
