import { useQuery } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import FoldersService from 'src/domain/Folder/services/FoldersService';
const useContentLibrary = ({ filter, page, direction, elementPerPage, key, }) => {
    const getFolders = async (page) => {
        const { content, totalElements, totalPages } = await FoldersService.getMainFolderItems({
            page,
            elementPerPage,
            category: 'folder',
            key,
            direction,
        });
        return { content, totalElements, totalPages };
    };
    const getMedias = async (page) => {
        const { content, totalElements, totalPages } = await FoldersService.getMainFolderItems({
            page,
            elementPerPage,
            category: 'media',
            key,
            direction,
        });
        return { content, totalElements, totalPages };
    };
    const getFoldersAndMedias = async (page) => {
        const { content, totalElements, totalPages } = await FoldersService.getMainFolderItems({
            page,
            elementPerPage,
            key,
            direction,
        });
        return { content, totalElements, totalPages };
    };
    return useQuery({
        queryKey: [
            QueryKeys.LIBRARY,
            { filter, page, elementPerPage, key, direction },
        ],
        queryFn: () => {
            const contentFetchers = {
                folders: getFolders,
                medias: getMedias,
                all_files: getFoldersAndMedias,
            };
            return contentFetchers[filter](page);
        },
        keepPreviousData: true,
    });
};
export default useContentLibrary;
