// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eBZ5u1vTQwF6gdJ5M4WF{width:100%}.eBZ5u1vTQwF6gdJ5M4WF .IcC3ZJ51iz7_X9FgICsw{display:flex;justify-content:space-between}.eBZ5u1vTQwF6gdJ5M4WF .jxzKOcS6sM51xUrHLSIi{--hc-p-neutral-color: var(--hc-color-neutral-1000);color:var(--hc-color-neutral-1000);margin:1rem 0}`, "",{"version":3,"sources":["webpack://./src/domain/Media/components/UploadAndImport/ImportMediaProvider/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAEA,4CACE,YAAA,CACA,6BAAA,CAGF,4CACE,kDAAA,CACA,kCAAA,CACA,aAAA","sourcesContent":[".container {\n  width: 100%;\n\n  .button {\n    display: flex;\n    justify-content: space-between;\n  }\n\n  .paragraph {\n    --hc-p-neutral-color: var(--hc-color-neutral-1000);\n    color: var(--hc-color-neutral-1000);\n    margin: 1rem 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `eBZ5u1vTQwF6gdJ5M4WF`,
	"button": `IcC3ZJ51iz7_X9FgICsw`,
	"paragraph": `jxzKOcS6sM51xUrHLSIi`
};
export default ___CSS_LOADER_EXPORT___;
