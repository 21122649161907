// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.m_QO3gu7k1dlc0xNMuBC{width:100%;height:100%;min-height:2.625rem}.O3FC1OobG4r_Z1LhiH4m{display:flex;gap:.5rem;justify-content:space-between}.O3FC1OobG4r_Z1LhiH4m>div{flex:1;display:flex;flex-direction:column;font-size:var(--text-2)}.O3FC1OobG4r_Z1LhiH4m>div .H2BKchuugVKOAf4Xf6xx{margin-bottom:var(--spacer-4)}.kjhNbSn_5l2L1wD1xEIw{width:100%;height:1px;background:var(--hc-color-neutral-200);margin-top:var(--spacer-5)}`, "",{"version":3,"sources":["webpack://./src/application/pages/Home/containers/PlanConsumption/FreemiumLayout/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,mBAAA,CAGF,sBACE,YAAA,CACA,SAAA,CACA,6BAAA,CAEA,0BACE,MAAA,CACA,YAAA,CACA,qBAAA,CACA,uBAAA,CAEA,gDACE,6BAAA,CAKN,sBACE,UAAA,CACA,UAAA,CACA,sCAAA,CACA,0BAAA","sourcesContent":[".contentLoader {\n  width: 100%;\n  height: 100%;\n  min-height: 2.625rem;\n}\n\n.row {\n  display: flex;\n  gap: 0.5rem;\n  justify-content: space-between;\n\n  & > div {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    font-size: var(--text-2);\n\n    .rowTitle {\n      margin-bottom: var(--spacer-4);\n    }\n  }\n}\n\n.separator {\n  width: 100%;\n  height: 1px;\n  background: var(--hc-color-neutral-200);\n  margin-top: var(--spacer-5);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentLoader": `m_QO3gu7k1dlc0xNMuBC`,
	"row": `O3FC1OobG4r_Z1LhiH4m`,
	"rowTitle": `H2BKchuugVKOAf4Xf6xx`,
	"separator": `kjhNbSn_5l2L1wD1xEIw`
};
export default ___CSS_LOADER_EXPORT___;
