// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zBoEIA5agYtAgINcsuPy{display:flex;gap:1rem;align-items:center;width:calc(50% - 3rem);min-height:6.25rem}.zBoEIA5agYtAgINcsuPy svg{width:1.5rem;height:1.5rem}.zBoEIA5agYtAgINcsuPy .YY6rAc6MkTzE3eL9neHi{display:flex;flex-direction:column;gap:.25rem}.zBoEIA5agYtAgINcsuPy .YY6rAc6MkTzE3eL9neHi .A9W5JjAlRuBIUKpZHpUb{font-size:var(--hc-size-text-medium);font-weight:var(--hc-font-weight-regular);color:var(--hc-color-neutral-900);margin-bottom:0}.zBoEIA5agYtAgINcsuPy .YY6rAc6MkTzE3eL9neHi .mmOwuFKSCNA_EvonjzKq{font-size:var(--hc-size-text-small);font-weight:var(--hc-font-weight-regular);color:var(--hc-color-neutral-500);margin-bottom:0;text-align:justify}`, "",{"version":3,"sources":["webpack://./src/application/pages/Home/containers/WelcomeHome/AdvantagesModal/AdvantagesInfo/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,QAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CAEA,0BACI,YAAA,CACA,aAAA,CAGJ,4CACI,YAAA,CACA,qBAAA,CACA,UAAA,CAEA,kEACI,oCAAA,CACA,yCAAA,CACA,iCAAA,CACA,eAAA,CAGJ,kEACI,mCAAA,CACA,yCAAA,CACA,iCAAA,CACA,eAAA,CACA,kBAAA","sourcesContent":[".advantageContainer {\n    display: flex;\n    gap: 1rem;\n    align-items: center;\n    width: calc(50% - 3rem);\n    min-height: 6.25rem;\n\n    svg {\n        width: 1.5rem;\n        height: 1.5rem;\n    }\n\n    .advantage {\n        display: flex;\n        flex-direction: column;\n        gap: .25rem;\n\n        .advantageTitle {\n            font-size: var(--hc-size-text-medium);\n            font-weight: var(--hc-font-weight-regular);\n            color: var(--hc-color-neutral-900);\n            margin-bottom: 0;\n        }\n\n        .advantageDescription {\n            font-size: var(--hc-size-text-small);\n            font-weight: var(--hc-font-weight-regular);\n            color: var(--hc-color-neutral-500);\n            margin-bottom: 0;\n            text-align: justify;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"advantageContainer": `zBoEIA5agYtAgINcsuPy`,
	"advantage": `YY6rAc6MkTzE3eL9neHi`,
	"advantageTitle": `A9W5JjAlRuBIUKpZHpUb`,
	"advantageDescription": `mmOwuFKSCNA_EvonjzKq`
};
export default ___CSS_LOADER_EXPORT___;
