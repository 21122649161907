import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useAuth from 'src/application/hooks/useAuth';
import { Trans, useTranslation } from 'react-i18next';
import { WelcomeCard } from '../../components/WelcomeCard';
import { MediaCard } from './MediaCard';
import { AdvantagesCard } from './AdvantagesCard';
import styles from './styles.module.scss';
export const WelcomeHome = () => {
    const { t } = useTranslation();
    const { user } = useAuth();
    return (_jsxs("div", { className: styles.container, children: [_jsx(WelcomeCard, { title: _jsx(Trans, { i18nKey: 'general.hello_highlighted_name', values: { name: user.profile.name }, components: { strong: _jsx("strong", {}) } }), description: t('general.hello_info') }), _jsxs("div", { className: styles.contentContainer, children: [_jsx(MediaCard, {}), _jsx(AdvantagesCard, {})] })] }));
};
