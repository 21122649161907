import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { Trans, useTranslation } from 'react-i18next';
import NiceModal from '@ebay/nice-modal-react';
import uploadMediaImg from 'public/assets/svg/upload_media_illustration.svg';
import { useFileUploadProcess } from 'src/domain/Media/modules/MediaUpload/contexts/MediaUploadContext/hooks/useFileUploadProcess';
import { GeneralStatus } from 'src/domain/Media/modules/MediaUpload/interfaces/FileUploadProps';
import { INITIAL_FILE_CONFIG_ALLOWED } from 'src/domain/Media/modules/MediaUpload/contexts/MediaUploadContext/constants';
import { List, ListItem } from 'src/ui/cosmosWeb/List';
import { FontAwesomeIcon, faChevronRight, } from 'src/application/icons/fontAwesome/light';
import { Card } from 'src/ui/cosmos/Card';
import { Button } from 'src/ui/cosmosWeb/Button';
import { convertSizeUnity, oneGbInBytes } from 'src/domain/User/Plan/utils';
import { useRef, useState } from 'react';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import useFeatureToggle from 'src/application/hooks/useFeatureToggle';
import { FeatureToggle } from 'src/application/interfaces/FeatureToggle';
import { UploadAndImportModal } from 'src/domain/Media/components/UploadAndImport/UploadAndImportModal';
import styles from './styles.module.scss';
export const MediaCard = () => {
    const { t } = useTranslation();
    const { isEnabled } = useFeatureToggle();
    const isImportAndUploadEnabled = isEnabled(FeatureToggle.HOTMART_PLAYER_ENABLE_MEDIA_IMPORT);
    const { onSelectFiles, generalUploadState } = useFileUploadProcess();
    const hasPendingFile = generalUploadState === GeneralStatus.UPLOADING;
    const inputFileRef = useRef(null);
    const [files, setFiles] = useState('');
    return (_jsxs(Card, { className: styles.mediaCard, children: [_jsx("div", { className: styles.icon, children: _jsx("img", { src: uploadMediaImg, alt: '' }) }), _jsxs("div", { className: styles.upload, children: [_jsx("p", { className: styles.title, children: t('home.welcome_home.new_content_card.title') }), _jsx("p", { className: styles.description, children: t('home.welcome_home.new_content_card.description') }), _jsx("div", { className: styles.buttons, children: _jsxs(List, { variation: 'boxes', className: styles.list, children: [_jsxs(ListItem, { className: styles.listButton, onClick: () => {
                                        inputFileRef.current?.click();
                                        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.HOME_NEW_UPLOAD_NEW_USER);
                                    }, children: [_jsx("input", { ref: inputFileRef, "data-testid": 'media-card-input-file', accept: INITIAL_FILE_CONFIG_ALLOWED.supportedFiles.join(','), type: 'file', multiple: true, disabled: hasPendingFile, value: files, onChange: (e) => {
                                                onSelectFiles(e.target.files, '');
                                                setFiles('');
                                            } }), _jsx("p", { className: styles.title, children: t('home.welcome_home.new_content_card.media_upload_button.title') }), _jsx("div", { slot: 'description', className: styles.description, children: _jsx(Trans, { i18nKey: 'home.welcome_home.new_content_card.media_upload_button.description', values: {
                                                    fileTypes: INITIAL_FILE_CONFIG_ALLOWED.allowedFiles.join(', '),
                                                    maxSize: convertSizeUnity(INITIAL_FILE_CONFIG_ALLOWED.byteSizeLimit / oneGbInBytes),
                                                }, components: { br: _jsx("br", {}) }, className: styles.title }) }), _jsx("div", { slot: 'actions', children: _jsx(Button, { slot: 'controls', variation: 'tertiary', className: styles.button, children: _jsx(FontAwesomeIcon, { icon: faChevronRight, className: 'fa-lg' }) }) })] }), isImportAndUploadEnabled && (_jsxs(ListItem, { className: styles.listButton, onClick: () => {
                                        NiceModal.show(UploadAndImportModal);
                                        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.HOME_NEW_IMPORT);
                                    }, "data-testid": 'media-import-list-item', children: [_jsx("p", { className: styles.title, children: t('home.welcome_home.new_content_card.media_import_button.title') }), _jsx("div", { slot: 'description', className: styles.description, children: t('home.welcome_home.new_content_card.media_import_button.description') }), _jsx("div", { slot: 'actions', children: _jsx(Button, { slot: 'controls', variation: 'tertiary', className: styles.button, children: _jsx(FontAwesomeIcon, { icon: faChevronRight, className: 'fa-lg' }) }) })] }))] }) })] })] }));
};
