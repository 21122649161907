// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ze9JTxgVaNL2xnbtEGGw .xoKPCSL2l3KmsvIg7CYr .LT_AJmgypyrh11YkdHO4{padding-top:2.125rem}.Ze9JTxgVaNL2xnbtEGGw .xoKPCSL2l3KmsvIg7CYr .Pgq_g_oRIRd1R4plg3bo,.Ze9JTxgVaNL2xnbtEGGw .xoKPCSL2l3KmsvIg7CYr .LT_AJmgypyrh11YkdHO4{--hc-p-neutral-color: var(--neutral-900)}.Ze9JTxgVaNL2xnbtEGGw .xoKPCSL2l3KmsvIg7CYr ._pkkkjyNibzYtzMU6GoA,.Ze9JTxgVaNL2xnbtEGGw .xoKPCSL2l3KmsvIg7CYr .Pgq_g_oRIRd1R4plg3bo{padding-top:1rem}.Ze9JTxgVaNL2xnbtEGGw .xoKPCSL2l3KmsvIg7CYr .OGCr0URqCMKL_MM8Y63f,.Ze9JTxgVaNL2xnbtEGGw .xoKPCSL2l3KmsvIg7CYr ._pkkkjyNibzYtzMU6GoA{--hc-p-neutral-color: var(--gray-600)}`, "",{"version":3,"sources":["webpack://./src/domain/Media/components/UploadAndImport/ImportMediaProviderModal/FourthStep/styles.module.scss"],"names":[],"mappings":"AAEI,kEACE,oBAAA,CAEF,oIACE,wCAAA,CAEF,oIACE,gBAAA,CAEF,oIACE,qCAAA","sourcesContent":[".stepContainer {\n  .instructions {\n    .subtitle {\n      padding-top: 2.125rem;\n    }\n    .inputTitle, .subtitle {\n      --hc-p-neutral-color: var(--neutral-900);\n    }\n    .content, .inputTitle {\n      padding-top: 1rem;\n    }\n    .note, .content {\n      --hc-p-neutral-color: var(--gray-600);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepContainer": `Ze9JTxgVaNL2xnbtEGGw`,
	"instructions": `xoKPCSL2l3KmsvIg7CYr`,
	"subtitle": `LT_AJmgypyrh11YkdHO4`,
	"inputTitle": `Pgq_g_oRIRd1R4plg3bo`,
	"content": `_pkkkjyNibzYtzMU6GoA`,
	"note": `OGCr0URqCMKL_MM8Y63f`
};
export default ___CSS_LOADER_EXPORT___;
