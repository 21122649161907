import { jsx as _jsx } from "react/jsx-runtime";
import { createBrowserRouter, RouterProvider as ReactRouterProvider, } from 'react-router-dom';
import { Home } from 'src/application/pages/Home';
import { Library } from 'src/application/pages/Library';
import Login from 'src/application/pages/Auth/login';
import Logout from 'src/application/pages/Auth/logout';
import Accounts from 'src/application/pages/Accounts';
import Folder from 'src/application/pages/Folder';
import ManagerAccount from 'src/domain/User/Accounts/containers/ManagerAccount';
import { Analytics } from 'src/domain/Media/modules/Analytics/containers/Analytics';
import { ThumbnailUpload } from 'src/domain/Media/modules/Thumbnail/containers/ThumbnailUpload';
import { Subtitle } from 'src/domain/Media/modules/Subtitle/containers/SubtitleContainer';
import { Domain } from 'src/domain/Media/modules/Domain/container/Domain';
import ShareMedia from 'src/domain/Media/modules/Share/container';
import Search from '../pages/Search';
import NotFound from '../pages/NotFound';
import Status from '../pages/Status';
import { DefaultLayout, SimpleLayout, MediaSettingsLayout } from '../Layout';
import PlaybackControlCustomizer from '../pages/Media/Settings/containers/PlaybackControlCustomizer';
import Redirect from './Redirect';
const router = createBrowserRouter([
    //Default layout routes
    {
        element: (_jsx(ManagerAccount, { children: _jsx(DefaultLayout, {}) })),
        children: [
            {
                path: '/',
                element: _jsx(Home, {}),
            },
            {
                path: '/library',
                element: _jsx(Library, {}),
            },
            {
                path: '/library/folder/:folderCode',
                element: _jsx(Folder, {}),
            },
            {
                path: '/library/media/:mediaCode',
                element: _jsx(MediaSettingsLayout, {}),
                children: [
                    { path: '', element: _jsx(Redirect, { to: 'thumbnail' }) },
                    { path: 'analytics', element: _jsx(Analytics, {}) },
                    { path: 'thumbnail', element: _jsx(ThumbnailUpload, {}) },
                    { path: 'controls', element: _jsx(PlaybackControlCustomizer, {}) },
                    { path: 'subtitle', element: _jsx(Subtitle, {}) },
                    { path: 'restriction', element: _jsx(Domain, {}) },
                    { path: 'share', element: _jsx(ShareMedia, {}) },
                ],
            },
            {
                path: '/search',
                element: _jsx(Search, {}),
            },
        ],
    },
    //Simple layout routes
    {
        element: _jsx(SimpleLayout, {}),
        children: [
            {
                path: '/accounts',
                element: _jsx(Accounts, {}),
            },
        ],
    },
    {
        path: '/auth/login',
        element: _jsx(Login, {}),
    },
    {
        path: '/auth/logout',
        element: _jsx(Logout, {}),
    },
    {
        path: '/status',
        element: _jsx(Status, {}),
    },
    {
        path: '*',
        element: _jsx(NotFound, {}),
    },
]);
export function RouterProvider() {
    return _jsx(ReactRouterProvider, { router: router });
}
