import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NiceModal, useModal } from 'src/infrastructure/libs/NiceModal';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/ui/cosmosWeb/Modal';
import { Paragraph } from 'src/ui/cosmosWeb/Paragraph';
import { Button } from 'src/ui/cosmosWeb/Button';
import circleCheck from 'public/assets/svg/circle_check.svg';
import styles from './styles.module.scss';
export const ImportSuccessModal = NiceModal.create(({ onDismiss }) => {
    const modal = useModal();
    const { t } = useTranslation();
    return (_jsx(Modal, { onCloseModal: () => {
            modal.remove();
            onDismiss?.();
        }, className: styles.modal, opened: modal.visible, dismissible: false, "data-testid": 'import-success-modal', children: _jsxs("div", { className: styles.modalBody, slot: 'description', children: [_jsx("img", { src: circleCheck, alt: t('library.modal.import_tutorial.successful.title'), width: 85, height: 85, className: styles.illustration }), _jsx(Paragraph, { size: 'base', className: styles.title, children: t('library.modal.import_tutorial.successful.title') }), _jsx(Paragraph, { size: 'small', className: styles.content, children: t('library.modal.import_tutorial.successful.content') }), _jsx("div", { className: styles.buttonContainer, children: _jsx(Button, { role: 'button', onClick: () => {
                            modal.hide();
                        }, className: styles.button, children: t('library.modal.import_tutorial.successful.button') }) })] }) }));
});
