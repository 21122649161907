// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.b8S61pyVG1YhfGutlDXx{width:100%;height:100%;min-height:2.625rem}.ipZmEStAz99OdRierd0x{width:100%;height:1px;background:var(--hc-color-neutral-200)}.oa5N6MlDhPFEU2wi8hhs{display:flex;gap:1rem}.oa5N6MlDhPFEU2wi8hhs span{flex:1}`, "",{"version":3,"sources":["webpack://./src/application/pages/Home/containers/PlanConsumption/DefaultLayout/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,mBAAA,CAGF,sBACE,UAAA,CACA,UAAA,CACA,sCAAA,CAGF,sBACE,YAAA,CACA,QAAA,CAEA,2BACE,MAAA","sourcesContent":[".contentLoader {\n  width: 100%;\n  height: 100%;\n  min-height: 2.625rem;\n}\n\n.separator {\n  width: 100%;\n  height: 1px;\n  background: var(--hc-color-neutral-200);\n}\n\n.alert {\n  display: flex;\n  gap: 1rem;\n\n  span {\n    flex: 1;\n  }\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentLoader": `b8S61pyVG1YhfGutlDXx`,
	"separator": `ipZmEStAz99OdRierd0x`,
	"alert": `oa5N6MlDhPFEU2wi8hhs`
};
export default ___CSS_LOADER_EXPORT___;
