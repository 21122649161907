export var MenuGroupColors;
(function (MenuGroupColors) {
    MenuGroupColors["browse"] = "blue";
    MenuGroupColors["discover"] = "purple";
    MenuGroupColors["extensions"] = "cyan";
})(MenuGroupColors || (MenuGroupColors = {}));
export var MenuToolsIntentEvent;
(function (MenuToolsIntentEvent) {
    MenuToolsIntentEvent["MENU_TOOLS_CONTENT_LIBRARY"] = "menu_tools.content_library";
    MenuToolsIntentEvent["MENU_TOOLS_SIDEMENU_AUDIENCE"] = "menu_tools.sidemenu.audience";
    MenuToolsIntentEvent["MENU_TOOLS_MY_PANEL"] = "menu_tools.my_panel";
    MenuToolsIntentEvent["MENU_TOOLS_MY_PRODUCTS"] = "menu_tools.my_products";
    MenuToolsIntentEvent["MENU_TOOLS_MY_SALES"] = "menu_tools.my_sales";
    MenuToolsIntentEvent["MENU_TOOLS_MY_ANALYSIS"] = "menu_tools.my_analysis";
    MenuToolsIntentEvent["MENU_TOOLS_COURSE_MANAGEMENT"] = "menu_tools.course_management";
    MenuToolsIntentEvent["MENU_TOOLS_BALANCE"] = "menu_tools.balance";
    MenuToolsIntentEvent["MENU_TOOLS_CLUB_MEMBERSHIP"] = "menu_tools.club_membership";
    MenuToolsIntentEvent["MENU_TOOLS_PAYMENTS_PAGE"] = "menu_tools.payments_page";
    MenuToolsIntentEvent["MENU_TOOLS_AFFILIATE_PROGRAM"] = "menu_tools.affiliate_program";
    MenuToolsIntentEvent["MENU_TOOLS_AFFILIATION_MARKET"] = "menu_tools.affiliation_market";
    MenuToolsIntentEvent["MENU_TOOLS_AFFILIATE"] = "menu_tools.affiliate";
    MenuToolsIntentEvent["MENU_TOOLS_AFFILIATE_TOOLS"] = "menu_tools.affiliate_tools";
    MenuToolsIntentEvent["MENU_TOOLS_CUSTOMER_SEGMENTATION"] = "menu_tools.customer_segmentation";
    MenuToolsIntentEvent["MENU_TOOLS_LINK_MANAGER"] = "menu_tools.link_manager";
    MenuToolsIntentEvent["MENU_TOOLS_TRACKING_PIXEL"] = "menu_tools.tracking_pixel";
    MenuToolsIntentEvent["MENU_TOOLS_MY_EXTENSIONS"] = "menu_tools.my_extensions";
    MenuToolsIntentEvent["MENU_TOOLS_KLICKPAGES"] = "menu_tools.klickpages";
    MenuToolsIntentEvent["MENU_TOOLS_KLICKSEND"] = "menu_tools.klicksend";
    MenuToolsIntentEvent["MENU_TOOLS_WEBINAR"] = "menu_tools.webinar";
    MenuToolsIntentEvent["MENU_TOOLS_REPORTS"] = "menu_tools.reports";
})(MenuToolsIntentEvent || (MenuToolsIntentEvent = {}));
